import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { handles } from '../../store';
import { RenderIf } from '../../utilities';
import { Tablist, ContainerModal } from '../../component';
const ModalCustomer = ({ idModal, title = 'Title Modal', allowedApp, dataCustomer, isReload }) => {
  //store
  const { fetchAPIPost, CheckInputType, CheckInputCharacter } = handles;
  //useQueryClient
  const queryClient = useQueryClient();
  //custom
  const companyCustom = {
    companyName: null,
    allowedApps: {
      PND: {
        status: false,
        description: {
          allowedUsers: 0,
          envelopes: [],
          overdate: ['Day', 1],
        },
      },
      reScale: {
        status: false,
      },
      reSort: {
        status: false,
      },
      TnT: {
        status: false,
        description: {
          allowedUsers: 0,
          overdate: ['Day', 1],
        },
      },
    },
  };
  const typeRetention = ['Day', 'Week', 'Month'];
  const retention = Array.from({ length: 30 }, (_, index) => index + 1);
  //useState
  const [companyAdd, setCompanyAdd] = useState(companyCustom);
  const [isShowPnD, setIsShowPnD] = useState(false);
  const [isShowTnT, setIsShowTnT] = useState(false);
  const [envolopCheck, setEnvolopCheck] = useState([]);
  // value default
  const [isShowRetention, setIsShowRetention] = useState(false);
  const [isShowTypeRetention, setIsShowTypeRetention] = useState(false);
  const [isShowRetentionTnT, setIsShowRetentionTnT] = useState(false);
  const [isShowTypeRetentionTnT, setIsShowTypeRetentionTnT] = useState(false);
  const [disabledCustom, setdisabledCustom] = useState([]);
  const [numberUserHave, setNumberUserHave] = useState(0);
  const [numberUserHaveTnT, setNumberUserHaveTnT] = useState(0);
  //useEffect
  useEffect(() => {
    setCompanyAdd(companyCustom);
    setIsShowPnD(false);
    setIsShowTnT(false);
    setdisabledCustom(['duplicate', 'envelopes', 'character']);
    // eslint-disable-next-line
  }, [isReload]);

  useEffect(() => {
    const pndNumberUser = dataCustomer?.reduce((total, customer) => {
      const data = JSON.parse(customer.allowedApps);
      const hasPND = data.PND?.status;
      if (hasPND) {
        return total + (+data.PND.description?.allowedUsers || 0);
      }
      return total;
    }, 0);
    setNumberUserHave(pndNumberUser);
  }, [dataCustomer]);

  useEffect(() => {
    const tntNumberUser = dataCustomer?.reduce((total, customer) => {
      const data = JSON.parse(customer.allowedApps);
      const hasTnT = data.TnT?.status;
      if (hasTnT) {
        return total + (+data.TnT.description?.allowedUsers || 0);
      }
      return total;
    }, 0);
    setNumberUserHaveTnT(tntNumberUser);
    console.log(tntNumberUser);
  }, [dataCustomer]);

  const handleCheckInput = (isHandle, type) => {
    if (isHandle === 'post' && !disabledCustom.includes(type)) {
      setdisabledCustom((prev) => [...prev, type]);
    } else if (isHandle === 'delete' && disabledCustom.includes(type)) {
      setdisabledCustom((prev) => prev.filter((d) => d !== type));
    }
  };
  const handleCheckName = (e) => {
    const { value } = e.currentTarget;
    setCompanyAdd((prev) => ({ ...prev, companyName: value.trim() }));
    handleCheckInput(CheckInputType(e.target.value.trim(), dataCustomer) ? 'post' : 'delete', 'duplicate');
    handleCheckInput(CheckInputCharacter(e.target.value.trim()) ? 'post' : 'delete', 'character');
    handleCheckInput(e.target.value.trim() ? 'post' : 'delete', 'name');
  };
  const handleCheckApp = (e) => {
    const { dataset, checked } = e.currentTarget;
    const companyUpdate = { ...companyAdd };

    if (companyUpdate.allowedApps && companyUpdate.allowedApps[dataset.name]) {
      let objectData;
      const description = {
        allowedUsers: 0,
        envelopes: [],
        overdate: ['Day', 1],
      };
      const descriptionTnT = {
        allowedUsers: 0,
        overdate: ['Day', 1],
      };
      if (dataset.name === 'PND') {
        objectData = {
          ...(checked ? { ...companyUpdate.allowedApps[dataset.name] } : { description: description }),
          status: checked,
        };
      } else if (dataset.name === 'TnT') {
        objectData = {
          ...(checked ? { ...companyUpdate.allowedApps[dataset.name] } : { description: descriptionTnT }),
          status: checked,
        };
      } else {
        objectData = {
          status: checked,
        };
      }
      companyUpdate.allowedApps = {
        ...companyUpdate.allowedApps,
        [dataset.name]: objectData,
      };
    }

    if (dataset.name === 'PND') {
      setIsShowPnD(checked);
    }
    if (dataset.name === 'TnT') {
      setIsShowTnT(checked);
    }
    setCompanyAdd(companyUpdate);
    handleCheckInput(checked ? 'post' : 'delete', dataset.name);
  };

  const handleSaveCustomer = () => {
    const customer = {
      ...companyAdd,
      allowedApps: JSON.stringify(companyAdd.allowedApps),
    };
    fetchAPIPost('Company', customer, queryClient, ['companies', 'quantityStatistics']);
    setCompanyAdd(companyCustom);
    setIsShowPnD(false);
    setIsShowTnT(false);
    setdisabledCustom(['duplicate', 'envelopes']);
  };
  const handleUpdatePNDConfig = (e) => {
    const { value, dataset } = e.currentTarget;
    let valueUpdateOverdate = null;
    if (dataset.name === 'overdate') {
      valueUpdateOverdate = [...companyAdd.allowedApps.PND.description.overdate];
      valueUpdateOverdate[dataset.location] = isNaN(dataset.value) ? dataset.value.trim() : +dataset.value;
    }

    if (dataset.name === 'envelopes') {
      if (dataset.handle === 'post') {
        valueUpdateOverdate = [...companyAdd.allowedApps.PND.description?.envelopes, dataset.value.trim()];
      } else if (dataset.handle === 'delete') {
        valueUpdateOverdate = companyAdd.allowedApps.PND.description?.envelopes.filter(
          (_, index) => index !== +dataset.index,
        );
      } else if (dataset.handle === 'put') {
        valueUpdateOverdate = companyAdd.allowedApps.PND.description?.envelopes.map((data, index) => {
          if (index === +dataset.index) {
            return value.trim();
          }
          return data;
        });
      }
      if (valueUpdateOverdate.length > 0) {
        const hasInvalidElements = valueUpdateOverdate.some(
          (item) => item === null || item === undefined || item === '',
        );
        const hasDuplicates = new Set(valueUpdateOverdate).size !== valueUpdateOverdate.length;
        setEnvolopCheck((prev) =>
          hasDuplicates ? [...prev, +dataset.index] : prev.filter((d) => d !== +dataset.index),
        );
        handleCheckInput(hasInvalidElements || hasDuplicates ? 'delete' : 'post', dataset.name);
      }
    }

    let valueUpdate =
      dataset.type === 'number' ? parseInt(valueUpdateOverdate || value, 10) : valueUpdateOverdate || value.trim();

    if (dataset.name === 'allowedUsers') {
      const limit = +allowedApp[0].allowedUsers - numberUserHave;
      const valueCheck = valueUpdate;
      if (valueCheck && !isNaN(valueCheck)) {
        const numValue = parseInt(valueCheck, 10);
        if (numValue > limit) {
          valueUpdate = limit;
        }
      }
    }
    const updatedCustomer = {
      ...companyAdd,
      allowedApps: {
        ...companyAdd.allowedApps,
        PND: {
          ...companyAdd.allowedApps.PND,
          description: {
            ...companyAdd.allowedApps.PND.description,
            [dataset.name]: valueUpdate,
          },
        },
      },
    };
    setCompanyAdd(updatedCustomer);
  };
  // TnT
  const handleUpdateTnTConfig = (e) => {
    const { value, dataset } = e.currentTarget;
    let valueUpdateOverdate = null;
    if (dataset.name === 'overdate') {
      valueUpdateOverdate = [...companyAdd.allowedApps.TnT.description.overdate];
      valueUpdateOverdate[dataset.location] = isNaN(dataset.value) ? dataset.value.trim() : +dataset.value;
    }

    let valueUpdate =
      dataset.type === 'number' ? parseInt(valueUpdateOverdate || value, 10) : valueUpdateOverdate || value.trim();

    if (dataset.name === 'allowedUsers') {
      const limit = +allowedApp[0].allowedUsers - numberUserHave;
      const valueCheck = valueUpdate;
      if (valueCheck && !isNaN(valueCheck)) {
        const numValue = parseInt(valueCheck, 10);
        if (numValue > limit) {
          valueUpdate = limit;
        }
      }
    }
    const updatedCustomer = {
      ...companyAdd,
      allowedApps: {
        ...companyAdd.allowedApps,
        TnT: {
          ...companyAdd.allowedApps.TnT,
          description: {
            ...companyAdd.allowedApps.TnT.description,
            [dataset.name]: valueUpdate,
          },
        },
      },
    };
    setCompanyAdd(updatedCustomer);
  };
  console.log(allowedApp);

  return (
    <ContainerModal
      idModal={idModal}
      title={title}
      listButton={[
        { name: 'CANCEL' },
        {
          name: 'SAVE',
          colorButton: 'btn-primary',
          isHandle: handleSaveCustomer,
          disabled:
            !disabledCustom.includes('name') ||
            !disabledCustom.includes('duplicate') ||
            !disabledCustom.includes('character') ||
            !disabledCustom.includes('envelopes'),
        },
      ]}
    >
      <Tablist
        listTab={[
          { name: 'Information', linkTab: 'inforCR' },
          { name: 'PnD', linkTab: 'PnDCR', isDisabled: !isShowPnD },
          { name: 'TnT', linkTab: 'TnTCR', isDisabled: !isShowTnT },
        ]}
        dependency={JSON.stringify(companyAdd) === JSON.stringify(companyCustom)}
        parentId={idModal}
      />
      <div className="tab-content tabs card-block">
        {/* information */}
        <div className="tab-pane active" id="inforCR" role="tabpanel">
          <div className="modal-body text-left row">
            <div className="row tab-pane" id="inforCR" role="tabpanel">
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Name</label>
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleCheckName}
                    value={companyAdd.companyName || ''}
                  />
                  {!disabledCustom?.includes('name') && <span style={{ color: 'red' }}>Name can't be blank !!!</span>}
                  {!disabledCustom?.includes('duplicate') && (
                    <span style={{ color: 'red' }}>Can't use this name! Please use another name !!!</span>
                  )}
                  {!disabledCustom?.includes('character') && (
                    <span style={{ color: 'red' }}>Only the character "." "-" "_" can be used. !!!</span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">App access</label>
                </div>
                <div className="col">
                  {Object.entries(companyAdd?.allowedApps || {}).map(([appName, appData]) => (
                    <div className="row" key={appName}>
                      <div className="col-sm-3 ds-flex">
                        <input
                          data-name={appName}
                          type="checkbox"
                          checked={appData?.status}
                          onChange={(e) => handleCheckApp(e)}
                        />
                      </div>
                      <div className="col-sm-3">
                        <label className="col-form-label">{appName}</label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* information */}
        {/* PnD */}
        <RenderIf isTrue={isShowPnD}>
          <div className={`tab-pane`} id="PnDCR" role="tabpanel">
            <div className={`modal-body text-left row `}>
              <div className="row tab-pane" id="PnDCR" role="tabpanel">
                <div className="form-group row">
                  <div className="col-sm-3">
                    <label className=" col-form-label">Users</label>
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      data-name="allowedUsers"
                      data-type="number"
                      min={0}
                      onChange={handleUpdatePNDConfig}
                      value={companyAdd?.allowedApps?.PND?.description?.allowedUsers || 0}
                    />
                    <span>
                      Max user is <strong>{allowedApp?.[0]?.allowedUsers - numberUserHave}</strong>
                    </span>
                    <span>
                      Max user is
                      <strong>
                        {allowedApp?.map(
                          (data) => data.applicationName === 'PND' && data.allowedUsers - numberUserHave,
                        )}
                      </strong>
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-3">
                    <label className=" col-form-label">Retention</label>
                  </div>
                  <div className="col-sm-3">
                    <div className="btn-dropdown" onClick={() => setIsShowRetention(!isShowRetention)}>
                      {+(companyAdd?.allowedApps?.PND?.description?.overdate?.[1] ?? 1)}
                      {!isShowRetention ? (
                        <i className="icofont icofont-rounded-up"></i>
                      ) : (
                        <i className="icofont icofont-rounded-down"></i>
                      )}
                    </div>
                    {isShowRetention && (
                      <div className="body-dropdown">
                        {retention.map((data) => (
                          <div
                            className="active-dropdown"
                            data-name="overdate"
                            data-value={data}
                            data-location={1}
                            onClick={(e) => {
                              handleUpdatePNDConfig(e);
                              setIsShowRetention(!isShowRetention);
                            }}
                            key={data}
                          >
                            {data}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="col">
                    <div className="btn-dropdown" onClick={() => setIsShowTypeRetention(!isShowTypeRetention)}>
                      {companyAdd?.allowedApps?.PND?.description?.overdate[0] || 'Day'}
                      {!isShowTypeRetention ? (
                        <i className="icofont icofont-rounded-up"></i>
                      ) : (
                        <i className="icofont icofont-rounded-down"></i>
                      )}
                    </div>
                    {isShowTypeRetention && (
                      <div className="body-dropdown">
                        {typeRetention.map((data) => (
                          <div
                            className="active-dropdown"
                            data-name="overdate"
                            data-value={data}
                            data-location={0}
                            onClick={(e) => {
                              handleUpdatePNDConfig(e);
                              setIsShowTypeRetention(!isShowTypeRetention);
                            }}
                            key={data}
                          >
                            {data}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-3">
                    <label className=" col-form-label">Envelope</label>
                  </div>
                  <RenderIf isTrue={(companyAdd?.allowedApps?.PND?.description?.envelopes || []).length > 0}>
                    <div className="col-sm-9">
                      {companyAdd?.allowedApps?.PND?.description?.envelopes.map((data, index) => (
                        <>
                          <div key={index} style={{ display: 'flex', marginBottom: '15px' }}>
                            <input
                              type="text"
                              className="form-control"
                              data-name="envelopes"
                              data-index={index}
                              data-handle="put"
                              onChange={handleUpdatePNDConfig}
                              value={data}
                            />

                            <button
                              className="btn"
                              style={{ marginLeft: '10px' }}
                              data-name="envelopes"
                              data-value={data}
                              data-index={index}
                              data-handle="delete"
                              onClick={handleUpdatePNDConfig}
                            >
                              <i id={data} className="icofont icofont-trash"></i>
                            </button>
                          </div>
                          {envolopCheck.includes(index) && (
                            <span style={{ color: 'red' }}>Envelope already exists, use another one</span>
                          )}
                        </>
                      ))}
                    </div>
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                      <button
                        className="btn"
                        data-name="envelopes"
                        data-value=""
                        data-handle="post"
                        onClick={handleUpdatePNDConfig}
                      >
                        <i className="icofont icofont-plus" style={{ color: 'green' }}></i>
                        Add more envelope
                      </button>
                    </div>
                  </RenderIf>
                  <RenderIf isTrue={(companyAdd?.allowedApps?.PND?.description?.envelopes || []).length === 0}>
                    <div className="col-sm-9">
                      <button
                        className="btn"
                        data-name="envelopes"
                        data-value=""
                        data-handle="post"
                        onClick={handleUpdatePNDConfig}
                      >
                        <i className="icofont icofont-plus" style={{ color: 'green' }}></i>
                        Add more envolop
                      </button>
                    </div>
                  </RenderIf>
                </div>
              </div>
            </div>
          </div>
        </RenderIf>
        <RenderIf isTrue={isShowTnT}>
          <div className={`tab-pane`} id="TnTCR" role="tabpanel">
            <div className={`modal-body text-left row `}>
              <div className="row tab-pane" id="TnTCR" role="tabpanel">
                <div className="form-group row">
                  <div className="col-sm-3">
                    <label className=" col-form-label">Users</label>
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      data-name="allowedUsers"
                      data-type="number"
                      min={0}
                      onChange={handleUpdateTnTConfig}
                      value={companyAdd?.allowedApps?.TnT?.description?.allowedUsers || 0}
                    />
                    <span>
                      Max user is <strong>{allowedApp?.[0]?.allowedUsers - numberUserHave}</strong>
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-3">
                    <label className=" col-form-label">Retention</label>
                  </div>
                  <div className="col-sm-3">
                    <div className="btn-dropdown" onClick={() => setIsShowRetentionTnT(!isShowRetentionTnT)}>
                      {+(companyAdd?.allowedApps?.TnT?.description?.overdate?.[1] ?? 1)}
                      {!isShowRetentionTnT ? (
                        <i className="icofont icofont-rounded-up"></i>
                      ) : (
                        <i className="icofont icofont-rounded-down"></i>
                      )}
                    </div>
                    {isShowRetentionTnT && (
                      <div className="body-dropdown">
                        {retention.map((data) => (
                          <div
                            className="active-dropdown"
                            data-name="overdate"
                            data-value={data}
                            data-location={1}
                            onClick={(e) => {
                              handleUpdateTnTConfig(e);
                              setIsShowRetentionTnT(!isShowRetentionTnT);
                            }}
                            key={data}
                          >
                            {data}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="col">
                    <div className="btn-dropdown" onClick={() => setIsShowTypeRetentionTnT(!isShowTypeRetentionTnT)}>
                      {companyAdd?.allowedApps?.TnT?.description?.overdate[0] || 'Day'}
                      {!isShowTypeRetentionTnT ? (
                        <i className="icofont icofont-rounded-up"></i>
                      ) : (
                        <i className="icofont icofont-rounded-down"></i>
                      )}
                    </div>
                    {isShowTypeRetentionTnT && (
                      <div className="body-dropdown">
                        {typeRetention.map((data) => (
                          <div
                            className="active-dropdown"
                            data-name="overdate"
                            data-value={data}
                            data-location={0}
                            onClick={(e) => {
                              handleUpdateTnTConfig(e);
                              setIsShowTypeRetentionTnT(!isShowTypeRetentionTnT);
                            }}
                            key={data}
                          >
                            {data}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </RenderIf>
      </div>
    </ContainerModal>
  );
};

export default ModalCustomer;
