import { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import config from './config';
import * as connectHub from './services/connectHub';
import { useStore, actions, handles } from './store';
import { privateRoutes, publicRoutes } from './routes/routes';
import { useQueryAPI } from './hooks';
function App() {
  const { dispatch } = useStore();
  const { toggleHubConnection } = actions;
  const { isLoggedIn, getCookie, setCookie, removeCookie } = handles;
  const location = useLocation();

  const userLogin = JSON.parse(getCookie('loggedInUser'));
  //hook
  const { useUserByName } = useQueryAPI;
  //Call API
  const { data: userByName, isLoading: isLoadingUserByName } = useUserByName(userLogin.user);
  const timeoutIdRef = useRef(null);

  const [, setRefresh] = useState(false);

  useEffect(() => {
    const createHubConnection = async () => {
      try {
        const response = await fetch('/files/assets/config.json');
        const data = await response.json();
        sessionStorage.setItem('hubHost', data.apiUrl);
        sessionStorage.setItem('scalerUrl', data.scalerUrl);
        sessionStorage.setItem('tntApiUrl', data.tntApiUrl);

        const hubConnection = await connectHub.createHubConnection();
        if (hubConnection) {
          dispatch(toggleHubConnection(hubConnection));
        }
      } catch (error) {
        console.error('Error fetching config or connecting to hub:', error);
      }
    };

    createHubConnection();
    // eslint-disable-next-line
  }, [dispatch, toggleHubConnection]);

  useLayoutEffect(() => {
    if (location.pathname !== config.routes.login) {
      removeCookie('lastVisitedPath');
      setCookie('lastVisitedPath', window.location.href);

      if (isLoggedIn) {
        const expirationTime = 1 / 12;
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        setCookie('loggedInUser_expires', Date.now() + expirationTime * millisecondsPerDay, {
          expires: expirationTime,
        });
        setCookie('loggedInUser', JSON.stringify(JSON.parse(getCookie('loggedInUser'))), {
          expires: expirationTime,
        });
      }

      const cookieExpirationDate = getCookie('loggedInUser_expires');
      const currentTime = new Date().getTime();
      const timeUntilTimestamp = cookieExpirationDate - currentTime;
      if (timeUntilTimestamp > 0) {
        timeoutIdRef.current = setTimeout(() => {
          setRefresh((prev) => !prev);
        }, timeUntilTimestamp);
      }

      const route = privateRoutes.find((route) => route.path === location.pathname);
      if (route && route.title) {
        document.title = route.title;
      }
    }
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
    // eslint-disable-next-line
  }, [location, isLoggedIn]);

  const getAccessible = (userByName) => {
    if (userByName.user === 'ADMIN') {
      return 'ADMIN';
    }

    const isAdmin = JSON.parse(userByName.permission || '[]').includes('admin');
    const isGroup = JSON.parse(userByName.userGroup || '[]');
    if (!userByName.external) {
      if (isAdmin) {
        return 'INTERNALADMIN';
      } else {
        return isGroup.length === 0 ? 'EMPLOYEE' : 'INTERNALBASIC';
      }
    } else if (userByName.external && isAdmin) {
      return 'EXTERNALADMIN';
    }

    return 'EMPLOYEE';
  };

  if (isLoadingUserByName) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      {privateRoutes
        .filter((route) => route.roles.includes(getAccessible(userByName)))
        .map((route, index) => (
          <Route
            key={publicRoutes.length + index}
            path={route.path}
            element={
              isLoggedIn() ? (
                <route.layout>
                  <route.component />
                </route.layout>
              ) : (
                <Navigate to={config.routes.login} />
              )
            }
          />
        ))}
      <Route path="*" element={<Navigate to={config.routes.dashboard} />} />
    </Routes>
  );
}

export default App;
