import Cookies from 'js-cookie';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './headerCustom.css';
import config from '../../../config';
import { useState, useEffect, useRef } from 'react';
import { useStore, handles } from '../../../store';
import { DropDownMenu } from '../../../component';
function Header() {
  const userLogin = JSON.parse(Cookies.get('loggedInUser'));

  const { state } = useStore();
  const { hubConnection } = state;
  const { removeCookie } = handles;

  const navigate = useNavigate();
  const [userData, setuserData] = useState({});
  const [isOpen, setIsOpen] = useState(false); // State for chatbox visibility
  const chatboxRef = useRef(null); // Reference to the chatbox element
  const [canAccessUser, setCanAccessUser] = useState(false);
  const [canAccessCustomer, setCanAccessCustomer] = useState(false);
  const [canAccessApp, setCanAccessApp] = useState(false);

  const handleClick = () => {
    if (isOpen === false) {
      document.getElementById('sidebar').hidden = false;
    } else {
      document.getElementById('sidebar').hidden = true;
    }
    setIsOpen(!isOpen); // Toggle open state on click
  };

  let location = useLocation();

  useEffect(() => {
    if (hubConnection) {
      //invoke
      hubConnection.invoke('ReadEmailUserByUserName', userLogin.user).catch((err) => console.log(err));
      hubConnection.on('SendReadEmailUserByUserName', (param) => {
        setuserData(param);
        let config = JSON.parse(param.userGroup);
        if (param.user === 'ADMIN') {
          setCanAccessApp(true);
          setCanAccessUser(true);
          setCanAccessCustomer(true);
        } else if (param.external === false && JSON.parse(param.permission).includes('admin')) {
          setCanAccessUser(true);
          setCanAccessCustomer(true);
        } else if (param.external === false && config.length > 0) {
          setCanAccessUser(true);
        } else if (param.external === true && JSON.parse(param.permission).includes('admin')) {
          setCanAccessUser(true);
        } else {
          setCanAccessUser(false);
          setCanAccessCustomer(false);
          setCanAccessApp(false);
        }
      });
    }
    // eslint-disable-next-line
  }, [hubConnection]);
  const handleLogout = async () => {
    removeCookie('loggedInUser');
    removeCookie('loggedInUser_expires');
    navigate(config.routes.login);
  };

  const handleSendDataToModal = (e) => {
    let _state = e.target.value;
    let componentID = e.target.name;

    document.getElementById(componentID).hidden = !_state;
    //check resort
    if (
      document.getElementById('ckSort1').checked &&
      document.getElementById('ckSort2').checked &&
      document.getElementById('ckSort3').checked &&
      document.getElementById('ckSort4').checked
    ) {
      // id="ckSortWALL"
      document.getElementById('ckSortWALL').checked = true;
      document.getElementById('allreSort1').hidden = false;
      document.getElementById('allreSort2').hidden = false;
    } else if (
      document.getElementById('ckSort1').checked ||
      document.getElementById('ckSort2').checked ||
      document.getElementById('ckSort3').checked ||
      document.getElementById('ckSort4').checked
    ) {
      //document.getElementById("ckSort4").checked =true;
      document.getElementById('allreSort1').hidden = false;
      document.getElementById('allreSort2').hidden = false;
    } else {
      document.getElementById('ckSortWALL').checked = false;
      document.getElementById('allreSort1').hidden = true;
      document.getElementById('allreSort2').hidden = true;
    }

    //check rescale
    if (
      document.getElementById('ckTrack1').checked &&
      document.getElementById('ckTrack2').checked &&
      document.getElementById('ckTrack21').checked &&
      document.getElementById('ckTrack22').checked &&
      document.getElementById('ckTrack3').checked
    ) {
      // id="ckSortWALL"
      document.getElementById('ckScaleWALL').checked = true;
      document.getElementById('allreScale1').hidden = false;
      document.getElementById('allreScale2').hidden = false;
    } else if (
      document.getElementById('ckTrack1').checked ||
      document.getElementById('ckTrack2').checked ||
      document.getElementById('ckTrack21').checked ||
      document.getElementById('ckTrack22').checked ||
      document.getElementById('ckTrack3').checked
    ) {
      document.getElementById('allreScale1').hidden = false;
      document.getElementById('allreScale2').hidden = false;
    } else {
      document.getElementById('ckScaleWALL').checked = false;
      document.getElementById('allreScale1').hidden = true;
      document.getElementById('allreScale2').hidden = true;
    }
  };
  const handleSendDataToModalResortAll = (e) => {
    let _state = e.target.value;

    document.getElementById('ckSort1').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckSort1').name,
        value: document.getElementById('ckSort1').checked,
      },
    });
    document.getElementById('ckSort2').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckSort2').name,
        value: document.getElementById('ckSort2').checked,
      },
    });
    document.getElementById('ckSort3').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckSort3').name,
        value: document.getElementById('ckSort3').checked,
      },
    });
    document.getElementById('ckSort4').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckSort4').name,
        value: document.getElementById('ckSort4').checked,
      },
    });
    //id='allreScale1'
    document.getElementById('allreSort1').hidden = !_state;
    document.getElementById('allreSort2').hidden = !_state;
  };
  const handleSendDataToModalreScaletAll = (e) => {
    let _state = e.target.value;

    document.getElementById('ckTrack1').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckTrack1').name,
        value: document.getElementById('ckTrack1').checked,
      },
    });
    document.getElementById('ckTrack2').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckTrack2').name,
        value: document.getElementById('ckTrack2').checked,
      },
    });
    document.getElementById('ckTrack21').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckTrack21').name,
        value: document.getElementById('ckTrack21').checked,
      },
    });
    document.getElementById('ckTrack22').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckTrack22').name,
        value: document.getElementById('ckTrack22').checked,
      },
    });
    document.getElementById('ckTrack3').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckTrack3').name,
        value: document.getElementById('ckTrack3').checked,
      },
    });
    document.getElementById('allreScale1').hidden = !_state;
    document.getElementById('allreScale2').hidden = !_state;
    //id='allreSort1'
  };
  //handleSendDataToModalReScaleAll
  const [isOpenM, setIsOpenM] = useState(false);
  const handleClickMobile = () => {
    if (isOpenM === false) {
      document.getElementById('leftIcon').hidden = false;
    } else {
      document.getElementById('leftIcon').hidden = true;
    }
    setIsOpenM(!isOpenM); // Toggle open state on click
  };
  return (
    <>
      <nav className="navbar header-navbar pcoded-header">
        <div className="navbar-wrapper">
          <div className="navbar-logo" style={{ padding: '0', marginLeft : '10px' }}>
            <a href={'#' + config.routes.dashboard}>
              <img
                className="img-fluid"
                style={{ height: '75px' }}
                src="./files/assets/images/logoTLS.png"
                alt="Theme-Logo"
              />
            </a>
            <NavLink className="mobile-options waves-effect waves-light" onClick={handleClickMobile}>
              <i className="feather icon-more-vertical"></i>
            </NavLink>
          </div>
          <div className="navbar-container container-fluid">
            <ul className="nav-left">
              <li>
                <NavLink onClick={window.toggleFullScreen} className="waves-effect waves-light">
                  <i className="full-screen feather icon-maximize"></i>
                </NavLink>
              </li>
            </ul>
            <ul className="nav-right">
              {location.pathname.includes('dashboard') && (
                <li className="header-notification">
                  <div className="dropdown-primary dropdown">
                    <div
                      className="displayChatbox dropdown-toggle"
                      onClick={handleClick}
                      ref={chatboxRef}
                      style={{ transition: isOpen ? 'slide 500ms ease-in-out' : 'none' }}
                    >
                      <i className="feather icon-settings"></i>
                    </div>
                  </div>
                </li>
              )}
              {/* now */}
              <DropDownMenu
                config={config}
                userData={userData}
                canAccessApp={canAccessApp}
                canAccessCustomer={canAccessCustomer}
                canAccessUser={canAccessUser}
              />
              <li className="user-profile header-notification">
                <div className="dropdown-primary dropdown">
                  <div className="dropdown-toggle" data-bs-toggle="dropdown">
                    <img
                      src="../files/assets/images/avatar-4.jpg"
                      className="img-radius"
                      style={{ marginTop: '10px' }}
                      alt="User-Profile"
                    />
                    <span>{userLogin.user}</span>
                    <i className="feather icon-chevron-down"></i>
                  </div>
                  <ul
                    className="show-notification profile-notification dropdown-menu"
                    data-dropdown-in="fadeIn"
                    data-dropdown-out="fadeOut"
                  >
                    {/* <li>{userLogin.user}</li>
                    <li>
                      <p>version 1.2</p>
                    </li> */}
                    <li onClick={handleLogout}>
                      <NavLink to="/">
                        <i className="feather icon-log-out"></i> Sign Out
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div id="sidebar" className="users p-chat-user showChat" style={{ display: 'block' }} hidden>
        <div className="had-container">
          <div className="p-fixed users-main">
            <div className="user-box">
              <div className="chat-search-box">
                <NavLink className="back_friendlist" onClick={handleClick}>
                  <i className="feather icon-x"></i>
                </NavLink>
                <div className="right-icon-control">
                  <div className="input-group input-group-button">
                    <h4>Customize Dashboard</h4>
                  </div>

                  {String(userData.appAccess).includes('reScale') && <hr style={{ padding: '0' }}></hr>}

                  {String(userData.appAccess).includes('reScale') && (
                    <table className="table table-hover m-b-0 without-header">
                      <tr>
                        <td>
                          <h5>reScale's widgets</h5>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="ckScaleWALL"
                            name=""
                            onChange={(e) => {
                              handleSendDataToModalreScaletAll({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Jobs Analysis Chart</span>
                        </td>
                        <td>
                          <input
                            id="ckTrack1"
                            type="checkbox"
                            name="wTrack1"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />{' '}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>All Last 24h </span>
                        </td>
                        <td>
                          <input
                            id="ckTrack2"
                            type="checkbox"
                            name="wTrack2"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Batch Last 24h </span>
                        </td>
                        <td>
                          <input
                            id="ckTrack21"
                            type="checkbox"
                            name="wTrack21"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>On Demand Last 24h </span>
                        </td>
                        <td>
                          <input
                            id="ckTrack22"
                            type="checkbox"
                            name="wTrack22"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Ticket</span>
                        </td>
                        <td>
                          <input
                            id="ckTrack3"
                            type="checkbox"
                            name="wTrack3"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />{' '}
                        </td>
                      </tr>
                    </table>
                  )}
                  {String(userData.appAccess).includes('reSort') && <hr style={{ padding: '0' }}></hr>}
                  {String(userData.appAccess).includes('reSort') && (
                    <table className="table table-hover m-b-0 without-header">
                      <tr>
                        <td>
                          <h5>reSort's widgets</h5>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="ckSortWALL"
                            name=""
                            onChange={(e) => {
                              handleSendDataToModalResortAll({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Jobs</span>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="ckSort1"
                            name="wSort1"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Latest Job</span>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="ckSort2"
                            name="wSort2"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>PostNord</span>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="ckSort3"
                            name="wSort3"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Bring</span>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="ckSort4"
                            name="wSort4"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />{' '}
                        </td>
                      </tr>
                    </table>
                  )}
                  {String(userData.appAccess).includes('PnD') && <hr style={{ padding: '0' }}></hr>}
                  {String(userData.appAccess).includes('PnD') && (
                    <table className="table table-hover m-b-0 without-header">
                      <tr>
                        <td>
                          <h5>PnD's widgets</h5>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            name=""
                            onChange={(e) => {
                              handleSendDataToModalreScaletAll({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Analysis</span>
                        </td>
                        <td>
                          <input
                            id="ckPnD1"
                            type="checkbox"
                            name="wPnD1"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />{' '}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Active jobs</span>
                        </td>
                        <td>
                          <input
                            id="ckPnD2"
                            type="checkbox"
                            name="wPnD2"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span>Finished jobs</span>
                        </td>
                        <td>
                          <input
                            id="ckPnD22"
                            type="checkbox"
                            name="wPnD22"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Documents </span>
                        </td>
                        <td>
                          <input
                            id="ckPnD21"
                            type="checkbox"
                            name="wPnD21"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
