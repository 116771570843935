import config from '../config';
//layout
import { DefaultLayout, LoginLayout } from '../layouts';
//page
import { Default, Products, Login, AppManager, CustomerManager, ManagerUser } from '../pages';

//Public
const publicRoutes = [{ path: config.routes.login, component: Login, layout: LoginLayout }];

const privateRoutes = [
  {
    path: config.routes.dashboard,
    title: 'TLS | Dashboard',
    component: Default,
    layout: DefaultLayout,
    roles: ['ADMIN', 'INTERNALADMIN', 'EXTERNALADMIN', 'EMPLOYEE'],
  },
  {
    path: config.routes.product,
    title: 'TLS | Products',
    component: Products,
    layout: DefaultLayout,
    roles: ['ADMIN', 'INTERNALADMIN', 'EXTERNALADMIN', 'EMPLOYEE'],
  },
  {
    path: config.routes.application,
    title: 'TLS | AppManager',
    component: AppManager,
    layout: DefaultLayout,
    roles: ['ADMIN'],
  },
  {
    path: config.routes.customer,
    title: 'TLS | Customer',
    component: CustomerManager,
    layout: DefaultLayout,
    roles: ['ADMIN', 'INTERNALADMIN'],
  },
  {
    path: config.routes.managerUser,
    title: 'TLS | Manager User',
    component: ManagerUser,
    layout: DefaultLayout,
    roles: ['ADMIN', 'INTERNALADMIN', 'INTERNALBASIC', 'EXTERNALADMIN'],
  },
];

export { publicRoutes, privateRoutes };
