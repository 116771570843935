import { useEffect, useRef, useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import { useQueryClient } from '@tanstack/react-query';
import { handles } from '../../store';
import { RenderIf } from '../../utilities';
import { useQueryAPI } from '../../hooks';
import { Tablist, FormRow, ContainerModal } from '../../component';
import { flatMap } from 'lodash';
const ModalAddUser = ({ idModal, title = 'Title Modal', isInternalBasic, isReload }) => {
  //store
  const { fetchAPIPost } = handles;
  //useQueryClient
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      user: '',
      password: '',
      email: null,
      fullName: null,
      external: 0,
      adminPermission: false,
      managerAdmin: false,
      application: false,
    },
  });
  //watch
  const isUser = watch('user');
  const isPassword = watch('password');
  const isExternal = watch('external');
  const isAdminPermission = watch('adminPermission');
  const isManagerAdmin = watch('managerAdmin');
  const isApplication = watch('application');
  //status
  const customerInAppCustom = [
    {
      name: 'PND',
      checked: false,
      admin: false,
    },
    {
      name: 'reScale',
      checked: false,
      admin: false,
    },
    {
      name: 'reSort',
      checked: false,
      admin: false,
    },
    {
      name: 'TnT',
      checked: false,
      admin: false,
    },
  ];
  const userLogin = JSON.parse(Cookies.get('loggedInUser'));
  //hook
  const { useCompanies, useUserByName, useUsers, useApplicationsLicenses } = useQueryAPI;
  //Call API
  const { data: userByName, isLoading: isLoadingUserByName } = useUserByName(userLogin.user);
  const { data: companies, isLoading: isLoadingCompanies } = useCompanies();
  const { data: users, isLoading: isLoadingUsers } = useUsers();
  const { data: applications, isLoading: isLoadingApplications } = useApplicationsLicenses();
  //useState
  const [choiceCustomer, setChoiceCustomer] = useState('select customer');
  const [customerInApp, setCustomerInApp] = useState(customerInAppCustom);
  const [customerInCustomer, setCustomerInCustomer] = useState([]);
  const [isShowRetention, setIsShowRetention] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isShowCustomer, setIsShowCustomer] = useState(true);
  const [isExternalAdmin, setIsExternalAdmin] = useState(false);
  // useRef
  const customerInCtyRef = useRef(null);
  // function
  useEffect(() => {
    reset();
    setCustomerInCustomer((prev) =>
      prev.map((cus) => {
        return { ...cus, checked: false };
      }),
    );
    if (userByName?.external === true && JSON.parse(userByName?.permission)?.includes('admin')) {
      setChoiceCustomer(companies?.find((d) => d.id === userByName.companyId).companyName);
      setIsShowCustomer(false);
      setIsExternalAdmin(true);
    } else if (
      (userByName?.external === false && JSON.parse(userByName?.permission)?.includes('admin')) ||
      (userByName?.external === false && JSON.parse(userByName.userGroup)?.length > 0)
    ) {
      setChoiceCustomer('select customer');
      setIsExternalAdmin(false);
      setIsShowCustomer(true);
    }
    // eslint-disable-next-line
  }, [isReload]);
  console.log(applications);

  useEffect(() => {
    if (users && applications) {
      let applicationsCheck = applications.map((app) => ({ ...app }));
      const usersCheck = users.map((user) => ({
        ...user,
        appAccess: JSON.parse(user.appAccess || '[]'),
      }));

      if (+isExternal === 1) {
        applicationsCheck = applicationsCheck.map((app) => {
          const numberUsers =
            usersCheck.filter((u) => (app.appName !== 'PND' || u.companyId === 0) && u.appAccess.includes(app.appName))
              .length || 0;
          let numberCompany = 0;
          if (app.appName === 'PND') {
            numberCompany = companies.reduce((total, company) => {
              let allowedApps;
              try {
                allowedApps = JSON.parse(company.allowedApps);
              } catch (error) {
                console.error('Invalid allowedApps format:', company.allowedApps);
                return total;
              }
              if (allowedApps?.PND?.status === true) {
                const allowedUsers = allowedApps?.PND?.description?.allowedUsers || 0;
                return total + allowedUsers;
              }

              return total;
            }, 0);
          }
          const allowedUsers = app.allowedUsers - numberUsers - numberCompany;
          return { appName: app.appName, allowedUsers };
        });
      } else if (+isExternal === 2 && choiceCustomer && choiceCustomer !== 'select customer') {
        const companySelect = companies?.find((d) => d.companyName === choiceCustomer);
        if (companySelect) {
          const allowedAppsObject = JSON.parse(companySelect.allowedApps);
          applicationsCheck = Object.keys(allowedAppsObject)
            .filter(
              (key) =>
                allowedAppsObject[key].status &&
                applicationsCheck
                  .find((app) => app.appName === key)
                  ?.tagUser?.replace(/"/g, '')
                  .split(',')
                  .map((item) => item.trim())
                  .includes('External'),
            )
            .map((appName) => {
              const numberUsers =
                usersCheck.filter((u) => u.companyId === companySelect.id && u.appAccess.includes(appName)).length || 0;
              const allowedUsers =
                (appName === 'PND'
                  ? allowedAppsObject[appName]?.description?.allowedUsers
                  : appName === 'TnT'
                  ? allowedAppsObject[appName]?.description?.allowedUsers
                  : applicationsCheck.find((app) => app.appName.toUpperCase() === appName.toUpperCase()).allowedUsers) -
                  numberUsers || 0;
              return {
                appName,
                allowedUsers,
              };
            });
          console.log(applicationsCheck);
        }
      }
      const allowedApps = JSON.parse(
        companies?.find((d) => +isExternal === 1 || d.companyName === choiceCustomer)?.allowedApps || '{}',
      );
      const customerUpdate = customerInAppCustom.filter(
        (cus) =>
          (applicationsCheck.find((app) => app.appName.toUpperCase() === cus.name.toUpperCase())?.allowedUsers || 0) !==
            0 &&
          (+isExternal === 1 || allowedApps[cus.name]?.status === true),
      );
      console.log(customerUpdate);
      console.log(customerInAppCustom);

      setCustomerInApp(customerUpdate);
    }
    // eslint-disable-next-line
  }, [choiceCustomer, isExternal]);
  useEffect(() => {
    if (userByName?.external === false && JSON.parse(userByName?.permission || '[]')?.includes('admin')) {
      //internal admin
      setChoiceCustomer('select customer');
    }
    setValue('adminPermission', false);
    setValue('managerAdmin', false);
    setValue('application', false);
    setCustomerInCustomer(
      customerInCustomer.map((d) => {
        return { ...d, checked: false };
      }),
    );
    // eslint-disable-next-line
  }, [isExternal]);
  useEffect(() => {
    if (userByName) {
      const config = JSON.parse(userByName.userGroup);
      const customerInCty = companies?.filter((d) => config.length === 0 || config.includes(d.id));
      const customerInCustomer = customerInCty?.map((d) => {
        return { id: d.id, name: d.companyName, checked: false };
      });
      customerInCtyRef.current = customerInCty;
      setCustomerInCustomer(customerInCustomer);
    }
  }, [companies, userByName]);

  useEffect(() => {
    const isValid = !!isUser && !!isPassword && isUser.trim() && isPassword.trim();
    setDisabled(!isValid);
  }, [isUser, isPassword]);

  const handleOpenSiteAdmin = (e) => {
    const data = customerInApp.map((d) => {
      if (d.name === e.target.dataset.id && e.target.dataset.change === 'checked') {
        d.checked = e.target.checked;
        if (e.target.checked === false) {
          d.admin = e.target.checked;
        }
      } else if (d.name === e.target.dataset.id && e.target.dataset.change === 'admin') {
        d.admin = e.target.checked;
      }
      return d;
    });
    setCustomerInApp(data);
  };
  console.log(customerInApp);

  const handleAddCompany = (e) => {
    const data = customerInCustomer.map((d) => {
      if (d.name === e.target.dataset.id) {
        d.checked = e.target.checked;
      }
      return d;
    });
    setCustomerInCustomer(data);
  };

  const onSubmit = (data) => {
    const { user, password, email, fullName, external, adminPermission, application } = data;
    const userUpdate = {
      user,
      password: btoa(password),
      email,
      fullName,
      permission: JSON.stringify(adminPermission !== false ? ['admin'] : []),
      userGroup: JSON.stringify(
        customerInCustomer
          .filter((d) => d.checked === true)
          .map((d) => d.id)
          .sort((a, b) => a - b) || '[]',
      ),
      appAccess: JSON.stringify(application ? customerInApp.filter((d) => d.checked === true).map((d) => d.name) : []),
      appRole: JSON.stringify(
        application ? customerInApp.filter((d) => d.admin === true).map((d) => `${d.name}Admin`) : [],
      ),
      lc: false,
      external: +external === 2,
      companyId: companies?.find((d) => d.companyName === choiceCustomer)?.id || 0,
    };
    console.log(userUpdate);

    fetchAPIPost('User', userUpdate, queryClient, ['users', 'quantityStatistics']);
    reset();
    setCustomerInCustomer((prev) =>
      prev.map((cus) => {
        return { ...cus, checked: false };
      }),
    );
    setChoiceCustomer('select customer');
  };

  if (isLoadingCompanies && isLoadingUserByName && isLoadingUsers && isLoadingApplications)
    return <div>Loading...</div>;

  return (
    <ContainerModal
      idModal={idModal}
      title={title}
      listButton={[
        { name: 'CANCEL' },
        { name: 'SAVE', colorButton: 'btn-primary', isHandle: handleSubmit(onSubmit), disabled: disabled },
      ]}
    >
      <Tablist
        listTab={[
          { name: 'Information', linkTab: 'infoAdd' },
          { name: 'Setting user', linkTab: 'SettingAdd' },
          { name: 'App setting', linkTab: 'AppAdd', isDisabled: !isApplication },
          { name: 'Customer setting', linkTab: 'CusAdd', isDisabled: !isManagerAdmin },
        ]}
        dependency={isReload}
        parentId={idModal}
      />
      <div className="tab-content tabs card-block">
        {/* information */}
        <div className="tab-pane active" id="infoAdd" role="tabpanel">
          <div className="modal-body text-left row">
            <div className="row tab-pane" id="infoAdd" role="tabpanel">
              <FormRow label="Name">
                <input
                  type="text"
                  className="form-control"
                  {...register('user', {
                    required: "Name can't blank !!!",
                    validate: (value) =>
                      !users.some((user) => ['ADMIN', user.user.toUpperCase()].includes(value.toUpperCase())) ||
                      "Can't use this user name !!!",
                    pattern: {
                      value: /^[a-zA-Z0-9._-]*$/,
                      message: 'Only letters, numbers, ".", "-", and "_" are allowed',
                    },
                  })}
                  placeholder="Enter your name"
                  onBlur={() => trigger('user')}
                />
                {errors.user && <span style={{ color: 'red' }}>{errors.user.message}</span>}
              </FormRow>
              <FormRow label="Password">
                <input
                  type="password"
                  className="form-control"
                  {...register('password', { required: "Password can't blank !!!" })}
                  placeholder="Enter your password"
                  onBlur={() => trigger('password')}
                />
                {errors.password && <span style={{ color: 'red' }}>{errors.password.message}</span>}
              </FormRow>
              <FormRow label="Email">
                <input type="text" className="form-control" {...register('email')} placeholder="Enter your email" />
              </FormRow>
              <FormRow label="Full name">
                <input
                  type="text"
                  className="form-control"
                  {...register('fullName')}
                  placeholder="Enter your full name"
                />
              </FormRow>
            </div>
          </div>
        </div>
        {/* PnD */}
        <div className="tab-pane" id="SettingAdd" role="tabpanel">
          <div className="modal-body text-left row">
            <div className="row tab-pane" id="SettingAdd" role="tabpanel">
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Role</label>
                </div>
                <div className={`col dis-center ${isInternalBasic ? 'hidden' : ''}`}>
                  <input type="radio" value={1} id="Internal" {...register('external')} />
                  <span>Internal</span>
                </div>
                <RenderIf
                  isTrue={applications?.some((app) =>
                    app?.tagUser
                      ?.replace(/"/g, '')
                      .split(',')
                      .map((item) => item.trim())
                      .includes('External'),
                  )}
                >
                  <div className="col dis-center">
                    <input type="radio" value={2} id="External" {...register('external')} />
                    <span>External</span>
                  </div>
                </RenderIf>
              </div>
              <div className={`${+isExternal !== 0 ? '' : 'hidden'}`}>
                <FormRow label="Type" hidden={+isExternal !== 0}>
                  <input
                    type="checkbox"
                    value={`adminPermission${+isExternal === 2 ? 'External' : 'Internal'}`}
                    {...register('adminPermission')}
                  />
                  <span>Admin</span>
                </FormRow>
                <FormRow
                  label="Application"
                  hidden={+isExternal === 1 || choiceCustomer !== 'select customer' || isExternalAdmin}
                >
                  <input type="checkbox" {...register('application')} />
                  <span>Access in App</span>
                </FormRow>
                {isShowCustomer && (
                  <FormRow label="Customer" type={'drop'} hidden={isAdminPermission !== 'adminPermissionInternal'}>
                    <RenderIf isTrue={+isExternal === 1}>
                      <input type="checkbox" {...register('managerAdmin')} />
                      <span>Manager admin</span>
                    </RenderIf>
                    {/* <RenderIf isTrue={JSON.parse(userByName.userGroup).length > 0 && +isExternal === 2}> */}
                    <RenderIf
                      isTrue={(+isExternal === 1 && JSON.parse(userByName.userGroup).length > 0) || +isExternal === 2}
                    >
                      <div
                        className="btn-dropdown"
                        style={{ maxWidth: 'none' }}
                        onClick={() => setIsShowRetention(!isShowRetention)}
                      >
                        {choiceCustomer}
                        {isShowRetention ? (
                          <i className="icofont icofont-rounded-up"></i>
                        ) : (
                          <i className="icofont icofont-rounded-down"></i>
                        )}
                      </div>
                      {isShowRetention && (
                        <div className="body-dropdown" style={{ maxWidth: 'none' }}>
                          {companies
                            ?.filter(
                              (d) =>
                                (!userByName.external && JSON.parse(userByName.permission)?.includes('admin')) ||
                                JSON.parse(userByName.userGroup || '[]')?.includes(d.id),
                            )
                            .map((data) => (
                              <div
                                key={data.companyName}
                                className="active-dropdown"
                                onClick={() => {
                                  setChoiceCustomer(data.companyName);
                                  setIsShowRetention(!isShowRetention);
                                }}
                              >
                                {data.companyName}
                              </div>
                            ))}
                        </div>
                      )}
                    </RenderIf>
                  </FormRow>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* app site */}
        <div className="tab-pane" id="AppAdd" role="tabpanel">
          <div className="modal-body text-left row">
            <div className="row tab-pane" id="AppAdd" role="tabpanel">
              <RenderIf isTrue={customerInApp.length > 0}>
                {customerInApp?.map((data) => (
                  <>
                    <Fragment key={data.name}>
                      <div className="form-group">
                        <div className="ds-flex">
                          <input
                            type="checkbox"
                            checked={data.checked}
                            data-id={data.name}
                            data-change="checked"
                            onChange={handleOpenSiteAdmin}
                          />
                          <label className=" col-form-label mg-5">{data.name}</label>
                        </div>
                      </div>
                      <RenderIf isTrue={data.checked}>
                        <div className="form-group row">
                          <div className="col-sm-3">
                            <label className=" col-form-label">Type</label>
                          </div>
                          <div className="col mg-auto">
                            <input
                              type="checkbox"
                              checked={data.admin}
                              data-id={data.name}
                              data-change="admin"
                              onChange={handleOpenSiteAdmin}
                            />
                            <span>Admin</span>
                          </div>
                        </div>
                      </RenderIf>
                    </Fragment>
                  </>
                ))}
              </RenderIf>
              <RenderIf isTrue={customerInApp.length === 0}>
                <p>Licensed quantity sold out</p>
              </RenderIf>
            </div>
          </div>
        </div>
        <div className="tab-pane" id="CusAdd" role="tabpanel">
          <div className={`modal-body text-left row `}>
            <div className="row tab-pane" id="CusAdd" role="tabpanel">
              {customerInCustomer?.map((data) => (
                <div key={data.name} className="form-group">
                  <div className="ds-flex">
                    <input type="checkbox" data-id={data.name} checked={data.checked} onChange={handleAddCompany} />
                    <label className="col-form-label mg-5">{data.name}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ContainerModal>
  );
};

export default ModalAddUser;
