import CryptoJS from "crypto-js";

const SECRET_KEY = "Reakit@2023"; // Use a strong key or store in environment variables

// Encrypt and store data in localStorage
export const setSecureItem = (key, value) => {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
    localStorage.setItem(key, encryptedData);
};

// Retrieve and decrypt data from localStorage
export const getSecureItem = (key) => {
    const encryptedData = localStorage.getItem(key);
    if (!encryptedData) return null;

    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
    } catch (error) {
        console.error("Decryption error:", error);
        return null;
    }
};

// Remove data from localStorage
export const removeSecureItem = (key) => {
    localStorage.removeItem(key);
};
