import { useState, useRef, useEffect } from 'react';
import { useStore } from '../../store';
import { useNavigate } from 'react-router-dom';
import { hubConnectionPnD } from '../../services/connectHub';
import { setSecureItem ,getSecureItem } from  "../../component/SecureStorage/secureStorage";
const Widget = ({
  title = 'Widget title',
  lable = 'none',
  data,
  data_2,
  data_3, // Adding data_3 prop
  isHasDropbox = false,
  isHaveCheckBox = false,
  setIsCheckValue,
  setTypeChange,
}) => {
  const { state } = useStore();
  const { hubConnection } = state;
  const navigate = useNavigate();

  // Refs
  const isCheckLinkRef = useRef(null);

  // State
  const [isOpenDrop, setIsOpenDrop] = useState(false);
  const [valueDrop, setValueDrop] = useState('Day');
  const [formattedStartTime, setFormattedStartTime] = useState('');
  const [formattedEndTime, setFormattedEndTime] = useState('');

  useEffect(() => {
    if (data?.startTime) {
      // Convert startTime from epoch to date
      const startTime = new Date(data.startTime);
      setFormattedStartTime(startTime.toLocaleString());

      // Set endTime to the current epoch time
      const endTime = new Date();
      setFormattedEndTime(endTime.toLocaleString());
    }
  }, [data]);

  // Handle Dropdown Open/Close
  const handleOpenDrop = () => {
    if (!isCheckLinkRef.current?.checked) {
      setIsOpenDrop(prev => !prev);
    }
  };

  // Handle Dropdown Value Selection
  const handleValueDrop = (e) => {
    const selectedValue = e.target.id;
    setTypeChange(2);
    setIsOpenDrop(false);
    setValueDrop(e.target.innerHTML);
   // let companyname = document.getElementById('slCompany');
    //let company_Name = companyname.options[companyname.selectedIndex].text;
    if(hubConnectionPnD)
      hubConnectionPnD
        .invoke('GetJobDetailsFromCompanyAsync', getSecureItem('cID_TnT'), selectedValue)
        .then(() => console.log('Data rendered'))
        .catch(err => console.error('SignalR Error:', err));
  };

  // Handle Checkbox Change
  const handleLinkDashboard = () => {
    setIsCheckValue(isCheckLinkRef.current?.checked || false);
  };

  // Handle Link to Search Job Page with custom status
  const handleLinkToSearchJob = (status) => {
    const startTime = data[0]?.startTime || '';
    const endTime = Date.now(); // Current epoch time
    //let companyname = document.getElementById('slCompany');
    // let company_Name = companyname.options[companyname.selectedIndex];
  //  navigate(`/searchjob?startTime=${startTime}&endTime=${endTime}&status=${status}&companyName=${getSecureItem('cID_TnT')}`); // Using companyName
  };

  return (
    <div className="card-body">
      <div className="row align-items-center">
        <div className="col">
          <div className="ds-flex al-center header-widget">
            <div className="col-6">
            <h6 className="m-b-25 fw-700" style={{textTransform:'uppercase'}}>     <img
                          src="./files/assets/images/tntIcon.svg"
                          style={{ width: '25px', marginRight: '5px', marginTop:'-4px' }}
                          alt="img"
                        />{title}</h6>
            </div>
            {isHasDropbox && (
              <div className="col-6">
                <div className="dropbox-block drop-first">
                  <button
                    className={`btn btn-dropbox ${isCheckLinkRef.current?.checked ? 'disable' : ''}`}
                    onClick={handleOpenDrop}
                  >
                    {valueDrop}
                    <i className={`icofont icofont-rounded-${isOpenDrop ? 'down' : 'right'} icon-drop`}></i>
                  </button>
                  {isOpenDrop && (
                    <div className="dropbox-content" onClick={handleValueDrop}>
                      <div id="OneDay">Day</div>
                      <div id="OneWeek">Week</div>
                      <div id="OneMonth">Month</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="row text-center">
            <div className="col">
              <h3
                className="f-w-700 text-c-blue"
                onClick={() => handleLinkToSearchJob('Completed')}
                style={{ cursor: 'pointer' }}
              >
                {data ? data.length : '_'}
              </h3>
              <p
                className="m-b-0 text-c-blue"
                onClick={() => handleLinkToSearchJob('Completed')}
                style={{ cursor: 'pointer' }}
              >
                {data_2 ? 'Completed' : lable !== 'none' ? lable : 'Jobs'}
              </p>
              {/* Displaying startTime and endTime */}


            </div>

            {data_2 && (
              <div className="col">
                <h3
                  className="f-w-700 text-c-blue"
                  onClick={() => handleLinkToSearchJob('Processing')}  // For processing jobs
                  style={{ cursor: 'pointer' }}
                >
                  {data_2.length}
                </h3>
                <p className="m-b-0 text-c-blue">Processing</p>
              </div>
            )}

            {data_3 && (
              <div className="col">
                <h3
                  className="f-w-700 text-c-red"
                  onClick={() => handleLinkToSearchJob('Failed')}// For failed jobs
                  style={{ cursor: 'pointer' }}
                >
                  {data_3.length}
                </h3>
                <p className="m-b-0 text-c-red">Failed</p>
              </div>
            )}
          </div>

          {isHaveCheckBox && (
            <div className="row">
              <div className="col" style={{ position: 'relative' }}>
                <input
                  type="checkbox"
                  name="link_to_analys_chart"
                  onClick={handleLinkDashboard}
                  ref={isCheckLinkRef}
                />
                <span className="span-checkBox">Link to analysis chart</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Widget;

