import { useQuery } from '@tanstack/react-query';
import { fetchAPIGet } from '../store/handles';

//Companies
export const useCompanies = (isEnabled = true) => {
  return useQuery({
    queryKey: ['companies'],
    queryFn: async () => {
      return fetchAPIGet('Company/Companies');
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEnabled,
  });
};

//
export const useApplicationsLicenses = (isEnabled = true) => {
  return useQuery({
    queryKey: ['applicationsLicenses'],
    queryFn: async () => {
      return fetchAPIGet('ApplicationsLicenses');
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEnabled,
  });
};

export const useUsers = (isEnabled = true) => {
  return useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      return fetchAPIGet('User/Users');
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEnabled,
  });
};

export const useQuantityStatistics = (isEnabled = true) => {
  return useQuery({
    queryKey: ['quantityStatistics'],
    queryFn: async () => {
      return fetchAPIGet('User/QuantityStatistics');
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEnabled,
  });
};

export const useUserByName = (name, isEnabled = true) => {
  return useQuery({
    queryKey: ['userByName'],
    queryFn: async () => {
      return fetchAPIGet(`User?name=${name}`);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEnabled,
  });
};
