import { useEffect, useLayoutEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { HeaderContent } from '../../component/page';
import { useStore, actions, handles } from '../../store';
import { OpenModal, ModalCustomer, ModalCustomerEdit, ModalDelete } from '../../component';
import { useQueryAPI } from '../../hooks';
function CustomerManager() {
  //store
  const { state, dispatch } = useStore();
  const { isCheckScript } = state;
  const { toggleCheckScript } = actions;
  const { runExistingScripts, fetchAPIDelete } = handles;
  //hook
  const { useCompanies, useQuantityStatistics } = useQueryAPI;
  //Call API
  const { data: companies, isLoading: isLoadingCompanies } = useCompanies();
  const { data: applications, isLoading: isLoadingApplications } = useQuantityStatistics();
  //useQueryClient
  const queryClient = useQueryClient();
  // useState
  const [dataAppChoice, setDataAppChoice] = useState([]);
  const [isReload, setIsReload] = useState(false);

  useLayoutEffect(() => {
    runExistingScripts(dispatch, toggleCheckScript, isCheckScript, true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const dataTable = initDataTable('tableNewJob', handleCompaniesData(companies));
    return () => {
      if (dataTable) {
        dataTable.destroy();
      }
    };
    // eslint-disable-next-line
  }, [companies]);

  const handleCompaniesData = (companies = []) => {
    return companies.map((company) => {
      const allowedApps = JSON.parse(company?.allowedApps || '{}');

      const activeApps = Object.keys(allowedApps)
        .filter((key) => allowedApps[key]?.status === true)
        .join(', ');

      return { ...company, allowedApps: activeApps };
    });
  };

  const initDataTable = (selector, data) => {
    const tableElement = document.getElementsByClassName(selector);
    if (!tableElement) {
      return;
    }

    const columns = [
      {
        data: 'companyName',
        title: 'Customer name',
      },
      { data: 'allowedApps', title: 'App' },
      {
        data: 'id',
        title: 'Setting',
        class: 'btn-edit',
        orderable: false,
        render: function (id) {
          return `
            <div class='col' data-id="${id}">
              <button type="button" class="btn waves-effect waves-light edit-btn" data-bs-toggle="modal" data-bs-target="#settingApps">
                <i class="icofont icofont-pen-alt-4"></i>
              </button>
              <button type="button" class="btn waves-effect waves-light" style="color: red" data-bs-toggle="modal" data-bs-target="#deleteCustomer">
                <i class="icofont icofont-trash"></i>
              </button>
            </div>
          `;
        },
      },
    ];

    let dataTable = window.$(`.${selector}`).DataTable({
      responsive: true,
      data: data,
      columns: columns,
      columnDefs: [
        {
          orderable: false,
          targets: 0,
        },
      ],
      order: [],
    });

    dataTable.off('click');

    window.$(`.${selector}`).on('click', 'tbody td.btn-edit .col', function (data) {
      const companyId = +data.currentTarget.dataset.id;
      if (companyId !== 0) {
        const dChoice = companies.find((d) => d.id === companyId);
        setDataAppChoice(dChoice);
      }
      setIsReload(!isReload);
    });

    return dataTable;
  };

  const handleDeleteCompany = () => {
    fetchAPIDelete(`Company/${dataAppChoice.id}`, queryClient, ['companies', 'quantityStatistics']);
  };

  if (isLoadingCompanies & isLoadingApplications) {
    return <div>Loading...</div>;
  }
  const handleReloadModal = () => {
    setIsReload(!isReload);
  };
  return (
    <div className="pcoded-content">
      <HeaderContent
        title={'Customer Manager'}
        breadcrumb={'user-and-roles'}
        link={'/users'}
        icon={'fa fa-suitcase'}
        typeIcon={'fa'}
      />
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <OpenModal idModal={'createCus'} title="Create new customer" onHandle={handleReloadModal}>
                        Create new customer
                      </OpenModal>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <div className="card-block">
                        <div className="dt-responsive table-responsive">
                          <table className="table table-striped table-bordered nowrap tableNewJob"></table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* delete modal */}
      <ModalDelete
        idModal="deleteCustomer"
        title="Delete Customer"
        textDelete="Warning : Delete customer will delete all user of this customer. Do you want continue ?"
        handleDelete={handleDeleteCompany}
      />
      {/* edit modal */}
      <ModalCustomerEdit
        idModal="settingApps"
        title="Edit Customer"
        customerSelected={dataAppChoice}
        setCustomerSelected={setDataAppChoice}
        allowedApp={applications}
        dataCustomer={companies}
      />
      {/* Add new */}
      <ModalCustomer
        idModal="createCus"
        title="Create new Customer"
        allowedApp={applications}
        dataCustomer={companies}
        isReload={isReload}
      />
    </div>
  );
}

export default CustomerManager;
