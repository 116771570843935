import { NavLink } from 'react-router-dom';

import Tooltip from '../../layouts/components/Header/Tooltip';
const DropDownMenu = ({ userData, canAccessUser = false, canAccessCustomer = false, canAccessApp = false, config }) => {
  console.log(canAccessUser);

  return (
    <li className="header-notification">
      <div className="dropdown-primary dropdown">
        <div className="dropdown-toggle" data-bs-toggle="dropdown">
          <i className="feather icon-layers" title="Products"></i>
        </div>
        <ul
          className="show-notification notification-view dropdown-menu"
          data-dropdown-in="fadeIn"
          data-dropdown-out="fadeOut"
        >
          <li style={{ textAlign: '-webkit-center' }}>
            <h6 style={{ fontSize: '20px' }}>TLS Apps</h6>
          </li>
          <li style={{ padding: '0', pointerEvents: 'none' }}>
            <hr style={{ padding: '0' }}></hr>
          </li>
          <li style={{ padding: '0' }}>
            <a style={{ padding: '1em 25px' }} href={'#' + config.routes.dashboard}>
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <i className="feather icon-home img-radius" style={{ fontSize: '34px' }}></i>
                </div>
                <div className="flex-grow-1">
                  <h5 className="notification-user" style={{ marginTop: '10px', marginLeft: '5px' }}>
                    TLS's Dashboard
                  </h5>
                </div>
                <div className="flex-end" style={{ marginTop: '10px' }}>
                  <Tooltip content="Open in new window" idTool="mytooltip1">
                    <NavLink href={'#' + config.routes?.dashboard} target="_blank">
                      <i className="fa fa-ellipsis-v"></i>
                    </NavLink>
                  </Tooltip>
                </div>
              </div>
            </a>
          </li>
          {String(userData?.appAccess).length > 2 && (
            <li style={{ padding: '0', pointerEvents: 'none' }}>
              <hr style={{ padding: '0' }}></hr>
            </li>
          )}
          {String(userData?.appAccess).includes('reScale') && (
            <li style={{ padding: '0' }}>
              <a style={{ padding: '1em 25px' }} href={'http://' + window.location.host + '/reScale/#/dashboard'}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <img
                      className="img-radius"
                      src="./files/assets/images/reScalelogo_singleBlack.png"
                      alt="Generic placeholder "
                    />
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="notification-user" style={{ paddingTop: '10px' }}>
                      reScale
                    </h5>
                  </div>
                  <div className="flex-end" style={{ marginTop: '10px' }}>
                    <Tooltip content="Open in new window" idTool="mytooltip2">
                      <a href={'http://' + window.location.host + '/reScale/#/dashboard'} target="_blank">
                        <i className="fa fa-ellipsis-v"></i>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </a>
            </li>
          )}
          {String(userData?.appAccess).includes('reSort') && (
            <li style={{ padding: '0' }}>
              <a style={{ padding: '1em 25px' }} href={'http://' + window.location.host + '/reSort/#/dashboard'}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <img
                      className="img-radius"
                      src="./files/assets/images/logoreSort_singleBlack.png"
                      alt="Generic placeholder "
                    />
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="notification-user" style={{ paddingTop: '10px' }}>
                      reSort
                    </h5>
                  </div>
                  <div className="flex-end" style={{ marginTop: '10px' }}>
                    <Tooltip content="Open in new window" idTool="mytooltip3">
                      <a href={'http://' + window.location.host + '/reSort/#/dashboard'} target="_blank">
                        <i className="fa fa-ellipsis-v"></i>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </a>
            </li>
          )}
          {String(userData?.appAccess).includes('PND') && (
            <li style={{ padding: '0' }}>
              <a style={{ padding: '1em 25px' }} href={'http://' + window.location.host + '/PnD/#/dashboard'}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <img className="img-radius" src="./files/assets/images/pnd_Icon.svg" alt="Generic placeholder " />
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="notification-user" style={{ paddingTop: '10px' }}>
                      PnD
                    </h5>
                  </div>
                  <div className="flex-end" style={{ marginTop: '10px' }}>
                    <Tooltip content="Open in new window" idTool="mytooltip3">
                      <a href={'http://' + window.location.host + '/PnD/#/dashboard'} target="_blank">
                        <i className="fa fa-ellipsis-v"></i>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </a>
            </li>
          )}
          {String(userData?.appAccess).includes('TnT') && (
            <li style={{ padding: '0' }}>
              <a style={{ padding: '1em 25px' }} href={'http://' + window.location.host + '/TnT/#/dashboard'}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <img className="img-radius" src="./files/assets/images/tntIcon.svg" alt="Generic placeholder " />
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="notification-user" style={{ paddingTop: '10px' }}>
                      TnT
                    </h5>
                  </div>
                  <div className="flex-end" style={{ marginTop: '10px' }}>
                    <Tooltip content="Open in new window" idTool="mytooltip3">
                      {/* <NavLink
                                 href={'http://' + window.location.host + '/TnT/#/dashboard'}
                                  target="_blank"
                                > */}
                      <a href={'http://' + window.location.host + '/TnT/#/dashboard'} target="_blank">
                        <i className="fa fa-ellipsis-v"></i>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </a>
            </li>
          )}
          {(canAccessApp || canAccessCustomer || canAccessUser) && (
            <li style={{ padding: '0', pointerEvents: 'none' }}>
              <hr style={{ padding: '0' }}></hr>
            </li>
          )}
          {canAccessApp && (
            <li style={{ padding: '0' }}>
              <a style={{ padding: '1em 25px' }} href={'#' + config.routes.application}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="fa fa-server img-radius" style={{ fontSize: '34px' }}></i>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="notification-user" style={{ marginTop: '10px', marginLeft: '5px' }}>
                      Applications manager
                    </h5>
                  </div>
                  <div className="flex-end" style={{ marginTop: '10px' }}>
                    <Tooltip content="Open in new window" idTool="mytooltip4">
                      <NavLink href={'#' + config.routes.application} target="_blank">
                        <i className="fa fa-ellipsis-v"></i>
                      </NavLink>
                    </Tooltip>
                  </div>
                </div>
              </a>
            </li>
          )}
          {canAccessCustomer && (
            <li style={{ padding: '0' }}>
              <a style={{ padding: '1em 25px' }} href={'#' + config.routes.customer}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="fa fa-suitcase img-radius" style={{ fontSize: '34px' }}></i>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="notification-user" style={{ marginTop: '10px', marginLeft: '5px' }}>
                      Customer manager
                    </h5>
                  </div>
                  <div className="flex-end" style={{ marginTop: '10px' }}>
                    <Tooltip content="Open in new window" idTool="mytooltip4">
                      <NavLink href={'#' + config.routes.customer} target="_blank">
                        <i className="fa fa-ellipsis-v"></i>
                      </NavLink>
                    </Tooltip>
                  </div>
                </div>
              </a>
            </li>
          )}
          {canAccessUser && (
            <li style={{ padding: '0' }}>
              <a style={{ padding: '1em 25px' }} href={'#' + config.routes.managerUser}>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="fa fa-users img-radius" style={{ fontSize: '34px' }}></i>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="notification-user" style={{ marginTop: '10px', marginLeft: '5px' }}>
                      Users manager
                    </h5>
                  </div>
                  <div className="flex-end" style={{ marginTop: '10px' }}>
                    <Tooltip content="Open in new window" idTool="mytooltip4">
                      <NavLink href={'#' + config.routes.managerUser} target="_blank">
                        <i className="fa fa-ellipsis-v"></i>
                      </NavLink>
                    </Tooltip>
                  </div>
                </div>
              </a>
            </li>
          )}
        </ul>
      </div>
    </li>
  );
};

export default DropDownMenu;
