import { useEffect, useState, useRef } from 'react'; // Ensure useRef is imported
import { useStore, handlesTnT } from '../../store';
import { HeaderPage, BodyPage, OpenModal, ModalCustomTimes, Widget } from '../../component';
import * as signalR from "@microsoft/signalr";
import { hubConnectionPnD } from '../../services/connectHub';
import { setSecureItem ,getSecureItem } from  "../../component/SecureStorage/secureStorage";
const WidgetTnT = () => {
    // 
    const { state } = useStore();
  const { hubConnection } = state;
  const { getCookie, ChartDashBoardAllTnT, ChartPieDeliveryChannels } = handlesTnT;
  const userLogin = JSON.parse(getCookie('loggedInUser'));

  // State initialization
  const [dataChart, setDataChart] = useState([]);
  const [dataChartDoc, setDataChartDoc] = useState([]);

  const [dataChartPage, setDataChartPage] = useState([]);
  const [dataWidgetJob, setDataWidgetJob] = useState([]);
  const [dataWidgetDocument, setDataWidgetDocument] = useState([]);
  const [dataWidgetCatalog, setDataWidgetCatalog] = useState([]);
  const [dataWidgetTask, setDataWidgetTask] = useState([]);
  const [dataWidgetTaskChart, setDataWidgetTaskChart] = useState([]);
  const [isCheckValueJob, setIsCheckValueJob] = useState(false);
  const [isCheckValueDocument, setIsCheckValueDocument] = useState(false);
  const [setValueDate, setSetValueDate] = useState(null);
  const [btnChart, setBtnChart] = useState('OneDay');
  const [innerCustom, setInnerCustom] = useState('Custom');
  const [valueDropJob, setValueDropJob] = useState('OneDay');
  const [valueDropDocument, setValueDropDocument] = useState('OneDay');
  const [typeChange, setTypeChange] = useState(3);
  const [timeFrom, setTimeFrom] = useState(null);
  const [timeTo, setTimeTo] = useState(null);
  const [dataNewJob, setDataNewJob] = useState([]);
  const [dataDraftJob, setDataDraftJob] = useState([]);
  const [dataFailed, setDataFailed] = useState([]);


  const [dataPiechart, setDataPiechart] = useState([]);
  const isFirstLoadRef = useRef(true); // Use ref to track the first load

  const [companyName, setCompanyName] = useState(getSecureItem('cID_TnT'));

  // Handle SignalR connections and data
  useEffect(() => {
    console.log('hub',hubConnectionPnD)
    if (hubConnectionPnD && hubConnection) {
      // Clean up old handlers
      //hubConnection.off('GetDataDashboard');
      //hubConnection.off('GetCustomdate');
      hubConnectionPnD.invoke('GetJobDetailsFromCompanyAsync', companyName, "OneDay").catch(console.log);
      hubConnectionPnD.on('GetDataDashboard', (param) => {
        console.log("1234444444",companyName);
        const dataTimeStamp = param
          .filter(data => data.status === 'Completed' && data.id.startsWith('J_'))
          .map(data => data.startTime);

        const dataTimeStampDoc = param
          .filter(data =>data.status === 'Processing' && data.id.startsWith('C_'))
          .map(data => ({
            startTime: data.startTime,       // Include startTime
            recordCount: data.recordCount     // Include recordCount if needed
          }));


        const dataTimeStampPage = param
        .filter(data => data.status === 'Processing' && data.id.startsWith('C_'))
        .map(data => ({
          startTime: data.startTime,
          pageCount: data.pageCount
        }))

        setDataChart(dataTimeStamp);

        const generateRepeatedStartTimes = () => {
        return dataTimeStampDoc.flatMap((record) =>
          record.recordCount > 0
            ? Array(record.recordCount).fill(record.startTime)  // Repeat startTime by recordCount
            : []
        );
      };


      // Generate the repeated start times for 'C_' records
      const repeatedStartTimes = generateRepeatedStartTimes();

      // Set the repeated start times in the state or handle them as needed
      setDataChartDoc(repeatedStartTimes);

       const generateRepeatedStartTimes_page = () => {
        return dataTimeStampPage.flatMap((record) =>
          record.pageCount > 0
            ? Array(record.pageCount).fill(record.startTime)  // Repeat startTime by pageCount
            : []
        );
      };


      // Generate the repeated start times for 'C_' records
      const repeatedStartTimes_page = generateRepeatedStartTimes_page();
      setDataChartPage(repeatedStartTimes_page)


        if (isFirstLoadRef.current) {
          setDataNewJob(param.filter(data => data.status === 'Completed' && data.id.startsWith('J_')));
          setDataDraftJob(param.filter(data => data.status === 'Processing' && data.id.startsWith('J_')));
          setDataFailed(param.filter(data =>
            ['Failed', 'Error', 'Incomplete', 'Unsuccessful'].includes(data.status) && data.id.startsWith('J_')
          ));

          setDataWidgetCatalog(param.filter(data => data.id.startsWith('C_')));
          setDataWidgetTask(param.filter(data => data.id.startsWith('T_')));
          setDataWidgetTaskChart(param
            .filter(data => data.status === 'Completed' && data.id.startsWith('T_'))
            .map(data => data.startTime)
          );

          isFirstLoadRef.current = true; // Update ref after first load
        }
      });

      hubConnectionPnD.on('GetCustomdate', (param) => {
        console.log(123, param);
        const dataTimeStampCustom = param.filter(data => data.status === 'Completed' && data.id.startsWith('J_')).map(data => data.startTime);
        //console.log(dataTimeStampCustom);
        setDataChart(dataTimeStampCustom); // Assuming param is the data for the custom date chart

      });
    }

    return () => {
      if (hubConnectionPnD) {
        hubConnectionPnD.off('GetDataDashboard');
        hubConnectionPnD.off('GetCustomdate');
      }
    };
  }, [hubConnection,getSecureItem('cID_TnT')]);


  const baseUrl = sessionStorage.getItem('tntApiUrl')+'api/trackandtrace/piechart/{companyname}';

  const fetchdataPieChart = async (companyName) => {
    //  let companyname = document.getElementById('slCompany');
    let company_Name = getSecureItem('cID_TnT');//companyname.options[companyname.selectedIndex].text;
   // setCompanyName(company_Name)
    try {
      const response = await fetch(baseUrl.replace('{companyname}', companyName));
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(123321, response);

      setDataPiechart(data); // Set the fetched data to state
    } catch (error) {
      console.error('Error fetching pie chart data:', error);
      // Optionally set error to state
    } finally {
      // Optionally stop loading  
    }
  };

  useEffect(() => {
    if (companyName) { // Only fetch if companyName is available
      fetchdataPieChart(companyName); // Fetch data on mount and when companyName changes
    }
  }, [companyName]); // Dependency array to run effect when companyName changes

  useEffect(() => {
    if (dataPiechart) {
      ChartPieDeliveryChannels(dataPiechart); // Render the chart when data is available
    }
  }, [dataPiechart]); // Trigger the chart rendering when data changes



  // Update data based on typeChange
  useEffect(() => {
    if (typeChange === 1) {
      setDataChart(dataChart); // Assuming dataChart is used here
    } else if (typeChange === 2) {
      setDataWidgetJob(dataChart); // Assuming dataChart is used for job data here
    } else if (typeChange === 3) {
      setDataChart(dataChart);
      setDataWidgetJob(dataChart);

    }
  }, [dataChart, typeChange]);




  // Handle chart updates
  useEffect(() => {
    if (btnChart === 'custom-date-chart') {
      if (timeFrom && timeTo) {
        ChartDashBoardAllTnT(dataChart,dataChartDoc,dataChartPage, btnChart, timeFrom, timeTo);



        //ChartPieDeliveryChannels(dataPiechart);
      } else {
        console.error('Invalid custom date range');
      }
    } else {
      ChartDashBoardAllTnT(dataChart,dataChartDoc,dataChartPage, btnChart);
      //ChartPieDeliveryChannels(dataPiechart);

    }
    if (isCheckValueJob) {
      setDataWidgetJob(dataChart);
    }
  }, [dataChart, btnChart, timeFrom, timeTo]);

  // Handle widget data
  useEffect(() => {
    if (isCheckValueJob) {
      setDataWidgetJob(dataChart);
    }
    if (isCheckValueDocument) {
      setDataWidgetDocument(dataWidgetTaskChart);
    }
  }, [isCheckValueJob, isCheckValueDocument]);


  // Handle button chart click
  const handleValueChart = (typeInput) => {
    setBtnChart(typeInput);
    //let companyname = document.getElementById('slCompany');
    let company_Name = getSecureItem('cID_TnT'); //companyname.options[companyname.selectedIndex].text;
    if (typeInput === 'custom-date-chart') {
      if (timeFrom && timeTo) {
        hubConnectionPnD.invoke('GetRecordsWithinTimeRangeAsync', company_Name, timeFrom, timeTo).catch(console.log);
      } else {
        console.error('Invalid custom date range');
      }
      hubConnectionPnD.on('GetCustomdate', (param) => {
        //console.log(param);
        setDataChart(param);
        //setdataPiechart(param);
      });
    } else {
      hubConnectionPnD.invoke('GetJobDetailsFromCompanyAsync', company_Name, typeInput).catch(console.log);
    }
  };

  return (
<>

        <div className="col-md-12 col-xl-4" id="wSort2">
          <div className="card sale-card">
            <div className="card-header">
            <img
                          src="./files/assets/images/tntIcon.svg"
                          style={{ width: '25px', marginRight: '5px' , marginTop:'-4px' }}
                          alt="img"
                        />
              <h5 style={{textTransform:'uppercase'}}>Delivery Channels</h5>
            </div>
            <div className="col-xl-12 col-md-4">
              <div
                id="allocation-chartTnT"
                className="chart-shadow"
                style={{
                  height: "auto",
                  minHeight: "100px", // Set a minimum height as needed
                  maxHeight: "200px", // Optionally, set a maximum height
                  overflow: "auto",   // Add scrolling if content overflows
                }}
              ></div>

            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xl-8" id="wTrack1">
            <div className="card sale-card">
              <div className="card-header">
              <img
                          src="./files/assets/images/tntIcon.svg"
                          style={{ width: '25px', marginRight: '5px' , marginTop:'-4px' }}
                          alt="img"
                        />
                <h5>ANALYSIS CHART</h5>
              </div>
              <div className="card-block">
                <div className="button-page">
                  {/* Company Name Dropdown */}
                  {/* <div className="form-group">
                    <label htmlFor="companySelect">Select Company:</label>
                    <select
                      id="companySelect"
                      className="form-control"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)} // Update company name on change
                    >
                      <option value="Testflow">Testflow</option>
                      <option value="Custtest">CustTest</option>
                    </select>
                  </div> */}
                  <button
                    id="OneDay"
                    className={`btn-round btn ${btnChart === 'OneDay' ? 'btn-primary' : ''}`}
                    onClick={() => handleValueChart('OneDay')}
                  >
                    Day
                  </button>
                  <button
                    id="OneWeek"
                    className={`btn-round btn ${btnChart === 'OneWeek' ? 'btn-primary' : ''}`}
                    onClick={() => handleValueChart('OneWeek')}
                  >
                    Week
                  </button>
                  <button
                    id="OneMonth"
                    className={`btn-round btn ${btnChart === 'OneMonth' ? 'btn-primary' : ''}`}
                    onClick={() => handleValueChart('OneMonth')}
                  >
                    Month
                  </button>
                  <button
                    id="OneYear"
                    className={`btn-round btn ${btnChart === 'OneYear' ? 'btn-primary' : ''}`}
                    onClick={() => handleValueChart('OneYear')}
                  >
                    Year
                  </button>
                  <OpenModal
                    title="Custom"
                    idModal={'custom-date-chart'}
                    type={'Dashboard'}
                    classStyle={btnChart === 'custom-date-chart' ? 'btn-primary' : ''}
                  >
                    {innerCustom}
                  </OpenModal>
                </div>
                <div id="deal-analytic-chartTnT" className="chart-shadow" style={{ height: '351px' }}></div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-xl-4">
            <div className="card comp-card" id="wTrack2">
              <Widget title={'Active jobs'} data={dataNewJob} data_2={dataDraftJob} data_3={dataFailed} />
            </div>

            <div className="card comp-card" id="wTrack22">
              <Widget
                title={'Jobs Failed'}
                lable={'Jobs'}
                data={dataFailed}
                isHasDropbox={true}
                isHaveCheckBox={true}
                setIsCheckValue={setIsCheckValueJob}
                setValueDropJob={setValueDropJob}
                setTypeChange={setTypeChange}
              />
            </div>
          </div>



        </div>
     
      <ModalCustomTimes
        idModal={'custom-date-chart'}
        title="Custom time chart"
        type={'date-pick'}
        setValueDate={setSetValueDate}
        setInnerCustom={setInnerCustom}
        setTimeFrom={setTimeFrom}
        setTimeTo={setTimeTo}
        handleValueChart={handleValueChart}
      />
   </>
  );
}

export default WidgetTnT;