import { useEffect, useLayoutEffect, useState } from 'react';
import { HeaderContent } from '../../component/page';
import { useStore, actions, handles } from '../../store';
function AppManager() {
  //store
  const { state, dispatch } = useStore();
  const { isCheckScript } = state;
  const { toggleCheckScript } = actions;
  const { runExistingScripts, formatTimestamp, getDate } = handles;

  // data
  const [dataApp, setDataApp] = useState([]);
  const [dataAppChoice, setDataAppChoice] = useState([]);
  // input
  const [nameApp, setNameApp] = useState('');
  // check
  const [isChange, setIsChange] = useState(false);
  const [externalValue, setExternalValue] = useState(false);
  // useState
  //useEffect
  // api
  const renderApiApp = async () => {
    await fetch(`${sessionStorage.getItem('hubHost')}api/ApplicationsLicenses`)
      .then((response) => response.json())
      .then((data) => {
        setDataApp(data);
      });
  };
  useEffect(() => {
    renderApiApp();
  }, []);
  // renderApiApp();
  useLayoutEffect(() => {
    runExistingScripts(dispatch, toggleCheckScript, isCheckScript, true);
    // eslint-disable-next-line
  }, []);
  const initDataTable = (selector, data) => {
    const tableElement = document.getElementsByClassName(selector);
    if (!tableElement) {
      return;
    }

    const columns = [
      {
        data: 'appName',
        title: 'App name',
        render: function (data, type, row) {
          return data;
        },
      },
      { data: 'allowedUsers', title: 'Users' },
      { data: 'tagUser', title: 'Tag user' },
      { data: 'licenseType', title: 'License type' },
      {
        data: 'expirationDate',
        title: 'Expiration Date ',
        render: function (data) {
          return getDate(data);
        },
      },
      {
        data: 'id',
        title: 'Setting',
        class: 'btn-edit',
        orderable: false,
        render: function (id) {
          return (
            `
              <button type="button" id="` +
            id +
            `" class="btn waves-effect waves-light"
             data-bs-toggle="modal"
                        data-bs-target="#settingApps">
              <i id="` +
            id +
            `" class="icofont icofont-pen-alt-4"></i>
              </button>
            `
          );
        },
      },
    ];

    let dataTable = window.$(`.${selector}`).DataTable({
      responsive: true,
      data: data,
      columns: columns,
      columnDefs: [
        {
          orderable: false,
          // className: 'select-checkbox',
          targets: 0,
        },
      ],
      // select: true,
      order: [],
    });
    // click
    dataTable.off('click');
    dataTable.on('click', 'tbody td.btn-edit', function (data) {
      if (data.target.id) {
        let dChoice = dataApp.find((d) => d.id === +data.target.id);
        setIsChange(false);
        setDataAppChoice(dChoice);
        setNameApp(dChoice.appName);
        setExternalValue(dChoice.tagUser?.includes('External'));
      }
    });
    return dataTable;
  };

  useEffect(() => {
    const dataTable = initDataTable('tableNewJob', dataApp);
    return () => {
      if (dataTable) {
        dataTable.destroy();
      }
    };
    // eslint-disable-next-line
  }, [dataApp]);
  const putAPI = async (dataChange) => {
    await fetch(`${sessionStorage.getItem('hubHost')}api/ApplicationsLicenses`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataChange),
    }).then((data) => {
      renderApiApp();
    });
  };
  const handleChangeApps = () => {
    putAPI(dataAppChoice);
  };
  const handleCheckExternal = (e) => {
    setIsChange(true);
    setExternalValue(!externalValue);
    if (!e.target.checked) {
      setDataAppChoice((prev) => ({ ...prev, tagUser: 'Internal' }));
    } else {
      setDataAppChoice((prev) => ({ ...prev, tagUser: 'Internal, External' }));
    }
  };
  return (
    <div className="pcoded-content">
      <HeaderContent
        title={'Applications manager'}
        breadcrumb={'user-and-roles'}
        link={'/users'}
        icon={'fa fa-server'}
        typeIcon={'fa'}
      />
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <div className="card-block">
                        <div className="dt-responsive table-responsive">
                          <table
                            // id="checkbox-select"
                            className="table table-striped table-bordered nowrap tableNewJob"
                          ></table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id={`settingApps`} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Setting</h4>
              <span id="success-edit-message" className="messages"></span>
              <button
                id="modalboxUpdate"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">App name</label>
                </div>
                <div className="col">
                  <input type="text" value={nameApp} className="form-control" readOnly />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Type user</label>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col-sm-3 ds-flex">
                      <input type="checkbox" checked readOnly disabled />
                    </div>
                    <div className="col-sm-3">
                      <label className=" col-form-label">Internal</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3 ds-flex">
                      <input type="checkbox" checked={externalValue} onClick={(e) => handleCheckExternal(e)} />
                    </div>
                    <div className="col-sm-3">
                      <label className=" col-form-label">External</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-default waves-effect " data-bs-dismiss="modal">
                  CANCEL
                </button>
                <button
                  type="button"
                  // id="btn-close-edit"
                  className={`btn waves-effect waves-light ${!isChange ? 'btn-disable' : 'btn-primary'}`}
                  onClick={handleChangeApps}
                  disabled={!isChange}
                  data-bs-dismiss="modal"
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <ModalSettingApps /> */}
    </div>
  );
}

export default AppManager;
