import Cookies from 'js-cookie';

export const isLoggedIn = () => {
  return Cookies.get('loggedInUser');
};

export const getCookie = (nameCookie) => {
  return Cookies.get(nameCookie);
};
export const removeCookie = (nameCookie) => {
  return Cookies.remove(nameCookie);
};

export const setCookie = (nameCookie, valueCookie, expirationCookie = 0) => {
  const setCookie = Cookies.set(nameCookie, valueCookie);
  return setCookie;
};

export const setCookieExpires = (nameCookie, valueCookie, expirationCookie) => {
  const setCookie = Cookies.set(nameCookie, valueCookie, {
    expires: expirationCookie,
  });
  return setCookie;
};
export const getDate = (millisecond) => {
  const timestamp = millisecond;
  const date = new Date(timestamp);
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  };
  const formattedDate = date.toLocaleString('en-US', options);
  return formattedDate;
};

export const convertToMilliseconds = (value, unit) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  if (unit === 'Hours') {
    const milliseconds = value * 60 * 60 * 1000;
    return Math.floor(Date.now() - milliseconds);
  } else if (unit === 'Days') {
    const milliseconds = value * millisecondsPerDay;
    return Math.floor(Date.now() - milliseconds);
  } else if (unit === 'Weeks') {
    const milliseconds = value * 7 * millisecondsPerDay;
    return Math.floor(Date.now() - milliseconds);
  } else if (unit === 'Months') {
    const milliseconds = value * 30 * millisecondsPerDay;
    return Math.floor(Date.now() - milliseconds);
  }

  return 0;
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
export const formatTimestampDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};
// day
// if (type === 'OneDay') {
//   for (let index = 0; index < 24; index++) {
//     dataDemo24h.push({
//       date: `${hoursPluss}:00`,
//       market1: '',
//       market2: '',
//       market3: '',
//     });
//     if (hoursPluss === 0) {
//       hoursPluss = 23;
//     } else {
//       hoursPluss--;
//     }
//   }
// export const ChartDashBoardAll = (dataFecth, type = 'OneDay', timeFrom = null, timeTo = null) => {
//   const dayNow = new Date();
//   let dayFrom = new Date(timeFrom);
//   let dayTo = new Date(timeTo);
//   let getHoursNow = dayNow.getHours();
//   let dataDay = [];
//   let dataWeek = [];
//   const monthChan = [4, 6, 9, 11];
//   const monthLe = [1, 3, 5, 7, 8, 10, 12];
//   const monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug'];
//   if (type === 'OneDay') {
//     let overDay = false;
//     for (let i = 0; i < 24; i++) {
//       if (overDay) {
//         dataDay.push({
//           date: `${getHoursNow--} `,
//           market1: '',
//           market2: '',
//           market3: '',
//         });
//       } else {
//         dataDay.push({
//           date: `${getHoursNow - i} `,
//           market1: '',
//           market2: '',
//           market3: '',
//         });
//       }

//       if (getHoursNow - i === 0) {
//         getHoursNow = 23;
//         overDay = true;
//       }
//     }
//   } else if (type === 'OneWeek') {
//     let overWeek = false;
//     const dayName = ['Mon', 'Tues', 'Wednes', 'Thurs', 'Fri', 'Satur', 'Sun'];
//     const hoursMoc = [0, 6, 12, 18];
//     let getDayNow = dayNow.getDay() - 1;
//     let getDateNow = dayNow.getDate();
//     let getMonth = dayNow.getMonth();
//     console.log(getMonth);
//     console.log(getDateNow);
//     let k = 0;
//     for (let i = 0; i <= 7; i++) {
//       for (let j = hoursMoc.length - 1; j >= 0; j--) {
//         if (overWeek) {
//           dataDay.push({
//             date: `${j === 0 ? `${dayName[getDayNow - k]}-` : ''}${hoursMoc[j]}`,
//             market1: '',
//             market2: '',
//             market3: '',
//           });
//           dataWeek.push({
//             day: `${getDayNow + 1 - k}`,
//             date: `${getDateNow - k}`,
//             hours: `${hoursMoc[j]}`,
//           });
//         } else {
//           dataDay.push({
//             date: `${j === 0 ? `${dayName[getDayNow - i]}-` : ''}${hoursMoc[j]}`,
//             market1: '',
//             market2: '',
//             market3: '',
//           });
//           dataWeek.push({
//             day: `${getDayNow + 1 - i}`,
//             date: `${getDateNow - i}`,
//             hours: `${hoursMoc[j]}`,
//           });
//         }
//         if (getDayNow - i < 0) {
//           overWeek = true;
//           getDayNow = dayName.length - 1;
//         }
//         if (getDateNow - i <= 0) {
//           if (getMonth === 4 || getMonth === 6 || getMonth === 9 || getMonth === 11) {
//             getDateNow = 30;
//           } else if (getMonth === 2) {
//             getDateNow = 28;
//           } else {
//             getDateNow = 31;
//           }
//         }
//       }
//       if (overWeek) k++;
//     }
//   } else if (type === 'OneMonth') {
//     let getMonthNow = dayNow.getMonth() + 1;
//     let getMonthBefor = getMonthNow - 1;
//     let overMont = false;
//     let getDateNow = dayNow.getDate();
//     let k = 0;
//     for (let i = 0; i < 30; i++) {
//       if (overMont) {
//         dataDay.push({
//           date: `${getDateNow - k}`,
//           market1: '',
//           market2: '',
//           market3: '',
//         });
//         k++;
//       } else {
//         dataDay.push({
//           date: `${getDateNow - i}`,
//           market1: '',
//           market2: '',
//           market3: '',
//         });
//       }

//       if (getDateNow - i <= 1 && !overMont) {
//         if (getMonthBefor === 4 || getMonthBefor === 6 || getMonthBefor === 9 || getMonthBefor === 11) {
//           getDateNow = 30;
//         } else if (getMonthBefor === 2) {
//           console.log('in dac biet');
//           getDateNow = 28;
//         } else {
//           getDateNow = 31;
//         }
//         overMont = true;
//       }
//     }
//   } else if (type === 'OneYear') {
//     let getMonthNow = dayNow.getMonth() + 1;
//     let overYear = false;
//     let k = 0;
//     for (let i = 0; i < 12; i++) {
//       if (overYear) {
//         dataDay.push({
//           date: `${getMonthNow - k}`,
//           market1: '',
//           market2: '',
//           market3: '',
//         });
//         k++;
//       } else {
//         dataDay.push({
//           date: `${getMonthNow - i}`,
//           market1: '',
//           market2: '',
//           market3: '',
//         });
//       }

//       if (getMonthNow - i === 1) {
//         overYear = true;
//         getMonthNow = 12;
//       }
//     }
//   } else if (type === 'custom-date-chart') {
//     // From
//     let getDateFrom = dayFrom.getDate();
//     let getMonthFrom = dayFrom.getMonth() + 1;
//     // To
//     let getDateTo = dayTo.getDate();
//     let getMonthTo = dayTo.getMonth() + 1;
//     if (getMonthFrom === getMonthTo) {
//       console.log('in');
//       for (let i = 0; i <= getDateTo - getDateFrom; i++) {
//         dataDay.push({
//           date: `${getDateTo - i}`,
//           market1: '',
//           market2: '',
//           market3: '',
//         });
//       }
//     } else {
//       let monthOut = getMonthTo - getMonthFrom;
//       if (getMonthFrom === 4 || getMonthFrom === 6 || getMonthFrom === 9 || getMonthFrom === 11) {
//         console.log('in');
//         let dayCheck = 30 - getDateFrom + getDateTo;
//         let overMonth = false;
//         for (let i = 0; i <= dayCheck; i++) {
//           if (overMonth) {
//             dataDay.push({
//               date: `${i}`,
//               market1: '',
//               market2: '',
//               market3: '',
//             });
//           } else {
//             dataDay.push({
//               date: `${getDateFrom + i}`,
//               market1: '',
//               market2: '',
//               market3: '',
//             });
//           }

//           if (getDateFrom + i === 30) {
//             overMonth = true;
//             i = 0;
//             dayCheck = getDateTo;
//           }
//         }
//       } else if (getMonthFrom === 2) {
//         console.log('in dac biet');
//         // let dayCheck = 30 - getDateFrom + getDateTo;
//       } else {
//         let dayCheck = 31 - getDateFrom + getDateTo;
//         let overMonth = false;
//         for (let i = 0; i <= dayCheck; i++) {
//           if (overMonth) {
//             dataDay.push({
//               date: `${i}`,
//               market1: '',
//               market2: '',
//               market3: '',
//             });
//           } else {
//             dataDay.push({
//               date: `${getDateFrom + i}`,
//               market1: '',
//               market2: '',
//               market3: '',
//             });
//           }

//           if (getDateFrom + i === 31) {
//             overMonth = true;
//             i = 0;
//             dayCheck = getDateTo;
//           }
//         }
//       }
//       dataDay = dataDay.reverse();
//     }
//   }

//   console.log(dataDay);
//   console.log(dataFecth);
//   // dayOne
//   // loop data
//   // dao chieu data
//   let dataChart = dataDay.reverse();

//   const dataWeekToChange = dataWeek.reverse();

//   if (type === 'OneDay') {
//     dataChart.forEach((data2) => {
//       let countJob = 0;
//       let countDocument = 0;
//       let countTemplate = 0;

//       dataFecth?.forEach((data) => {
//         let getHour = new Date(data.startTimestamp).getHours();

//         if (getHour === parseInt(data2.date)) {
//           countJob++;
//         }
//       });
//       data2.market1 = countJob;
//     });
//   } else if (type === 'OneWeek') {
//     for (let i = 0; i < dataChart.length; i++) {
//       let countJob = 0;

//       for (let j = 0; j < dataFecth?.length; j++) {
//         let getHour = new Date(dataFecth[j].startTimestamp).getHours();
//         let getDate = new Date(dataFecth[j].startTimestamp).getDay();

//         if (getDate === parseInt(dataWeekToChange[i].day)) {
//           if (getHour >= parseInt(dataWeekToChange[i].hours)) {
//             if (getHour <= parseInt(dataWeekToChange[i + 1].hours)) {
//               countJob++;
//             }
//           }
//         }
//       }
//       dataChart[i].market1 = countJob;
//     }
//   } else if (type === 'OneMonth') {
//     dataChart.forEach((data) => {
//       let countJob = 0;
//       dataFecth?.forEach((data2) => {
//         let getDate = new Date(data2.startTimestamp).getDate();
//         if (getDate === parseInt(data.date)) {
//           countJob++;
//         }
//       });
//       data.market1 = countJob;
//     });
//   } else if (type === 'OneYear') {
//     dataChart.forEach((data) => {
//       let countJob = 0;
//       dataFecth?.forEach((data2) => {
//         let getMonth = new Date(data2.startTimestamp).getMonth() + 1;

//         if (getMonth === parseInt(data.date)) {
//           countJob++;
//         }
//       });
//       data.market1 = countJob;
//     });
//   } else if (type === 'custom-date-chart') {
//     dataChart.forEach((data) => {
//       let countJob = 0;
//       dataFecth?.forEach((data2) => {
//         let getDate = new Date(data2.startTimestamp).getDate();
//         if (getDate === parseInt(data.date)) {
//           countJob++;
//         }
//       });
//       data.market1 = countJob;
//     });
//   }
//   window.AmCharts.makeChart('deal-analytic-chart', {
//     type: 'serial',
//     theme: 'light',
//     dataDateFormat: 'YYYY-MM-DD HH:MM',
//     precision: 2,
//     valueAxes: [
//       {
//         id: 'v1',
//         position: 'left',
//         autoGridCount: false,
//         labelFunction: function (value) {
//           return '$' + Math.round(value) + 'M';
//         },
//       },
//       {
//         id: 'v2',
//         gridAlpha: 0,
//         autoGridCount: false,
//       },
//     ],
//     graphs: [
//       {
//         id: 'g1',
//         valueAxis: 'v2',
//         bullet: 'round',
//         bulletBorderAlpha: 1,
//         bulletColor: '#FFFFFF',
//         bulletSize: 8,
//         hideBulletsCount: 50,
//         lineThickness: 3,
//         lineColor: '#2162ed',
//         type: 'smoothedLine',
//         title: 'Job',
//         useLineColorForBulletBorder: true,
//         valueField: 'market1',
//         balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
//       },
//       {
//         id: 'g2',
//         valueAxis: 'v2',
//         bullet: 'round',
//         bulletBorderAlpha: 1,
//         bulletColor: '#FFFFFF',
//         bulletSize: 8,
//         hideBulletsCount: 50,
//         lineThickness: 3,
//         lineColor: '#ed3c21',
//         type: 'smoothedLine',
//         title: 'Document',
//         useLineColorForBulletBorder: true,
//         valueField: 'market2',
//         balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
//         hidden: true,
//       },

//       {
//         id: 'g3',
//         valueAxis: 'v2',
//         bullet: 'round',
//         bulletBorderAlpha: 1,
//         bulletColor: '#FFFFFF',
//         bulletSize: 8,
//         hideBulletsCount: 50,
//         lineThickness: 3,
//         lineColor: '#35ed21',
//         type: 'smoothedLine',
//         title: 'Page',
//         useLineColorForBulletBorder: true,
//         valueField: 'market3',
//         balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
//         hidden: true,
//       },
//     ],
//     chartCursor: {
//       pan: true,
//       valueLineEnabled: true,
//       valueLineBalloonEnabled: true,
//       cursorAlpha: 0,
//       valueLineAlpha: 0.2,
//     },
//     chartScrollbar: {
//       autoGridCount: true,
//       graph: 'g1',
//       oppositeAxis: true,
//       scrollbarHeight: 40,
//     },
//     categoryField: 'date',
//     categoryAxis: {
//       // parseDates: true,
//       // dateFormatter: {
//       //   dateFormat: 'yyyy-MM-dd',
//       // },
//       dashLength: 1,
//       minorGridEnabled: true,
//     },
//     legend: {
//       useGraphSettings: true,
//       position: 'top',
//     },
//     balloon: {
//       borderThickness: 1,
//       shadowAlpha: 0,
//     },
//     dataProvider: dataChart,
//   });
// };
const createData = (timesDb, timesDoc, type, fromTimes = 0, toTimes = 0) => {
  const data = [];
  let end, start;

  const config = {
    OneDay: {
      adjustStart: (start) => start.setHours(end.getHours() - 24),
      interval: 1000 * 60 * 60,
    },
    OneWeek: {
      adjustStart: (start) => start.setHours(end.getHours() - 24 * 7),
      interval: 1000 * 60 * 60,
    },
    OneMonth: {
      adjustStart: (start) => start.setDate(end.getDate() - 30),
      interval: 1000 * 60 * 60 * 24,
    },
    OneYear: {
      adjustStart: (start) => start.setFullYear(end.getFullYear() - 1),
      interval: 1000 * 60 * 60 * 24,
    },
  };

  const { adjustStart, interval } = config[type];
  if (!adjustStart) {
    throw new Error('Invalid type');
  }
  if (fromTimes !== 0 && toTimes !== 0) {
    start = new Date(fromTimes);
    end = new Date(toTimes);
  } else {
    end = new Date();
    start = new Date(end);
    adjustStart(start);
  }

  const timeDiff = end.getTime() - start.getTime();
  const steps = Math.floor(timeDiff / interval);

  for (let i = 0; i <= steps; i++) {
    const current = new Date(start.getTime() + i * interval);

    if (type === 'OneWeek' && ![0, 12].includes(current.getHours())) {
      continue;
    }

    const formattedDate = `${current.getFullYear()}-${String(current.getMonth() + 1).padStart(2, '0')}-${String(
      current.getDate(),
    ).padStart(2, '0')}${['OneDay', 'OneWeek'].includes(type) ? ` ${String(current.getHours()).padStart(2, '0')}:00` : ''
      }`;
    data.push({ date: formattedDate, market1: 0, market2: 0, market3: 0 });
  }
  // jobs
  timesDb?.forEach((timeDb) => {
    const itemDate = new Date(timeDb);
    if (itemDate >= start && itemDate <= end) {
      let index;
      if (type === 'OneWeek') {
        index = Math.floor((itemDate - start) / (1000 * 60 * 60 * 24));
      } else {
        index = Math.floor((itemDate - start) / interval);
      }
      if (index >= 0 && index < data.length) {
        console.log(index);
        data[index].market1++;
      }
    }
  });
  //documents
  timesDoc?.forEach((timeDb) => {
    const itemDate = new Date(timeDb);
    if (itemDate >= start && itemDate <= end) {
      let index;
      if (type === 'OneWeek') {
        index = Math.floor((itemDate - start) / (1000 * 60 * 60 * 24));
      } else {
        index = Math.floor((itemDate - start) / interval);
      }
      if (index >= 0 && index < data.length) {
        data[index].market2++;
      }
    }
  });

  return data;
};

const getTypeFromTimeRange = (fromTimes, toTimes) => {
  const fromDate = new Date(fromTimes);
  const toDate = new Date(toTimes);

  if (toDate < fromDate) {
    return null;
  }

  const maxDateRange = {
    OneDay: 3,
    OneWeek: 7,
    OneMonth: 30,
    OneYear: 365,
  };

  const diffDays = (toDate - fromDate) / (1000 * 60 * 60 * 24);
  const type = Object.keys(maxDateRange).find((type) => diffDays <= maxDateRange[type]);

  return type || 'OneYear';
};

export const ChartDashBoardAll = (dataFecth, dataDocument = [], type = 'OneDay', fromTimes = 0, toTimes = 0) => {
  let adjustedType = type;
  if (fromTimes !== 0 && toTimes !== 0) {
    adjustedType = getTypeFromTimeRange(fromTimes, toTimes);
  }

  const dataChart = createData(dataFecth, dataDocument, adjustedType, fromTimes, toTimes);
  console.log('dataChart ::', dataChart);
  window.AmCharts.makeChart('deal-analytic-chartPnD', {
    type: 'serial',
    theme: 'light',
    precision: 2,
    valueAxes: [
      {
        id: 'v1',
        position: 'left',
        autoGridCount: false,
        labelFunction: function (value) {
          return '$' + Math.round(value) + 'M';
        },
      },
      {
        id: 'v2',
        gridAlpha: 0,
        autoGridCount: false,
      },
    ],
    graphs: [
      {
        id: 'g1',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#2162ed',
        type: 'smoothedLine',
        title: 'Jobs',
        useLineColorForBulletBorder: true,
        valueField: 'market1',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
      },
      {
        id: 'g2',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#ed3c21',
        type: 'smoothedLine',
        title: 'Documents',
        useLineColorForBulletBorder: true,
        valueField: 'market2',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
        hidden: true,
      },

      // {
      //   id: 'g3',
      //   valueAxis: 'v2',
      //   bullet: 'round',
      //   bulletBorderAlpha: 1,
      //   bulletColor: '#FFFFFF',
      //   bulletSize: 8,
      //   hideBulletsCount: 50,
      //   lineThickness: 3,
      //   lineColor: '#35ed21',
      //   type: 'smoothedLine',
      //   title: 'Pages',
      //   useLineColorForBulletBorder: true,
      //   valueField: 'market3',
      //   balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
      //   hidden: true,
      // },
    ],
    chartCursor: {
      pan: true,
      valueLineEnabled: true,
      valueLineBalloonEnabled: true,
      cursorAlpha: 0,
      valueLineAlpha: 0.2,
    },
    chartScrollbar: {
      autoGridCount: true,
      graph: 'g1',
      oppositeAxis: true,
      scrollbarHeight: 40,
    },
    categoryField: 'date',
    categoryAxis: {
      parseDates: true,
      minPeriod: 'hh',
      dashLength: 1,
      minorGridEnabled: true,
      dateFormats: [
        { period: 'fff', format: 'JJ:NN:SS' },
        { period: 'ss', format: 'JJ:NN:SS' },
        { period: 'mm', format: 'JJ:NN' },
        { period: 'hh', format: 'JJ:NN' },
        { period: 'DD', format: 'MMM DD' },
        { period: 'WW', format: 'MMM DD' },
        { period: 'MM', format: 'MMM' },
        { period: 'YYYY', format: 'YYYY' },
      ],
    },
    legend: {
      useGraphSettings: true,
      position: 'top',
    },
    balloon: {
      borderThickness: 1,
      shadowAlpha: 0,
    },
    dataProvider: dataChart,
  });
};

export const checkInput = (input, data = null) => {
  let check = true;
  // console.log(data);
  if (input === '') {
    check = false;
  } else if (data) {
    check = true;
    data?.map((d) => {
      if (d.intergrationName === input) {
        check = false;
      }
    })
  }
  return check;
}