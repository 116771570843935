import { useState, useRef, useEffect } from 'react';
import config from '../../config';
import { handles } from '../../store';
import * as handlerUserService from '../../services/handlerUserService';
import { privateRoutes } from '../../routes/routes';
function Login() {
  const { getCookie, setCookie } = handles;

  //useRef
  const usernameRef = useRef('');
  const passwordRef = useRef('');

  // useState
  const [isLoginFalse, setIsLoginFalse] = useState(false);

  useEffect(() => {
    const createHubConnection = async () => {
      try {
        const response = await fetch('/files/assets/config.json');
        const data = await response.json();
        sessionStorage.setItem('hubHost', data.apiUrl);
        sessionStorage.setItem('scalerUrl', data.scalerUrl);
      } catch (error) {
        console.error('Error fetching config or connecting to hub:', error);
      }
    };

    createHubConnection();
    // eslint-disable-next-line
  }, []);

  const getAccessible = (userByName) => {
    if (userByName.user === 'ADMIN') {
      return 'ADMIN';
    }

    const isAdmin = JSON.parse(userByName.permission || '[]').includes('admin');
    const isGroup = JSON.parse(userByName.userGroup || '[]');
    if (!userByName.external) {
      if (isAdmin) {
        return 'INTERNALADMIN';
      } else {
        return isGroup.length === 0 ? 'EMPLOYEE' : 'INTERNALBASIC';
      }
    } else if (userByName.external && isAdmin) {
      return 'EXTERNALADMIN';
    }

    return 'EMPLOYEE';
  };

  //handle
  const handleLogin = async () => {
    const user = usernameRef.current.value;
    const passWord = btoa(passwordRef.current.value);
    const accountResponse = await handlerUserService.loginAccount(user, passWord);
    if (accountResponse) {
      const user = {
        user: accountResponse.user,
      };
      const expirationTime = 1 / 48;
      const millisecondsPerDay = 24 * 60 * 60 * 1000;

      setCookie('loggedInUser', JSON.stringify(user));
      setCookie('loggedInUser_expires', Date.now() + expirationTime * millisecondsPerDay);

      setTimeout(() => {
        let pathLogin = '#' + config.routes.dashboard;
        const pathStore = getCookie('lastVisitedPath');
        if (pathStore) {
          const urlPath = new URL(pathStore).hash.slice(1);

          const pathCheck = privateRoutes.find((route) => urlPath.startsWith(route.path));

          if (pathCheck.roles.includes(getAccessible(accountResponse))) pathLogin = pathStore;
        }
        window.location.href = pathLogin;
      }, 500);
    } else {
      setIsLoginFalse(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
    if (isLoginFalse) {
      setIsLoginFalse(false);
    }
  };

  return (
    <section className="login-block">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <form className="md-float-material form-material">
              <div className="text-center">
                <img
                  className="img-fluid"
                  style={{ height: '75px' }}
                  src="./files/assets/images/logoTLS.png"
                  alt="Theme-Logo"
                />
              </div>
              <div className="auth-box card">
                <div className="card-block">
                  <div className="row m-b-20">
                    <div className="col-md-12">
                      <h3 className="text-center txt-primary">Sign In</h3>
                    </div>
                  </div>
                  <p className="text-muted text-center p-b-5">Sign in to your account</p>
                  <div className="form-group form-primary">
                    <input
                      type="text"
                      name="user-name"
                      placeholder="Enter your username"
                      className="form-control"
                      required
                      ref={usernameRef}
                      onKeyDown={handleKeyPress}
                    />
                    <span className="form-bar"></span>
                    <label className="form-label ">Username</label>
                  </div>
                  <div className="form-group form-primary">
                    <input
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                      className="form-control"
                      required
                      ref={passwordRef}
                      onKeyDown={handleKeyPress}
                    />
                    {isLoginFalse && (
                      <span className="form-bar" style={{ color: '#ff5370' }}>
                        Sorry, that fill are wrong. Try again?
                      </span>
                    )}

                    <label className="form-label ">Password</label>
                  </div>
                  {/* <div className="row m-t-25 text-start">
                    <div className="col-12">
                      <div className="checkbox-fade fade-in-primary">
                        <label className="form-label">
                          <input type="checkbox" value="" />
                          <span className="cr">
                            <i className="cr-icon icofont icofont-ui-check txt-primary"></i>
                          </span>
                          <span className="text-inverse">Remember me</span>
                        </label>
                      </div>
                      <div className="forgot-phone text-end float-end">
                        <a href="/#" className="text-end f-w-600">
                          Forgot Password?
                        </a>
                      </div>
                    </div>
                  </div> */}
                  <div className="row m-t-30">
                    <div className="col-md-12">
                      <div className="d-grid">
                        <button
                          type="button"
                          className="btn btn-primary btn-md waves-effect text-center m-b-20"
                          onClick={handleLogin}
                        >
                          SIGN IN
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
