import { RenderIf } from '../../utilities';
const FormRow = ({ label, children, error, hidden = true, errorClass = 'text-c-red', type = null }) => {
  return (
    <RenderIf isTrue={hidden}>
      <div className="form-group row">
        <div className="col-sm-3">
          <label className=" col-form-label">{label}</label>
        </div>
        <div className={`col mg-auto`}>
          {children}
          <RenderIf isTrue={error}>
            <span className={errorClass}>{error}</span>
          </RenderIf>
        </div>
      </div>
    </RenderIf>
  );
};

export default FormRow;
