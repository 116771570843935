const routes = {
  login: '/',
  dashboard: '/dashboard',
  product: '/Products',
  user: '/users',
  company: '/Company',
  application: '/AppManager',
  customer: '/CustomerManager',
  managerUser : '/ManagerUser'
};

export default routes;
